export enum ApplyScreenEnum{
	APPLICATION='Application Form',
	SUMMARY='Summary'
}

export enum ActionApplyEnum{
	SET_APPLICATION_FORM='SET_APPLICATION_FORM',
	SET_CURRENT_APPLICATION_SCREEN='SET_CURRENT_APPLICATION_SCREEN',
	CHANGE_APPLICATION_FORM='CHANGE_APPLICATION_FORM',
	SET_ERROR_ARRAY='SET_ERROR_ARRAY',
	SET_CURRENT_SCREEN='SET_CURRENT_SCREEN',
	SET_USER_INFO='SET_USER_INFO'
}