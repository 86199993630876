import {memo} from "react";

const removeIc = require('../../../image/icon/delete_ic.svg')

const SimpleQuestionItem = ({ item, simpleQuestion, setSimpleQuestion }) => {

  const onRemoveHandler = () => {
    setSimpleQuestion(prev => prev.filter(x => x.id !== item.id))
  }

  const onChangeHandler = (value) => {
    const newState = simpleQuestion.map(obj => {
      if (obj.id === item.id) {
        return { ...obj, value: value }
      }
      return obj
    })

    setSimpleQuestion(newState)
  }

  return (
    <div className="simple-questions__item">
      <input
        type="text"
        className="simple-questions__item__input"
        value={item.value}
        onChange={(e) => onChangeHandler(e.target.value)}
      />
      <div
        className="simple-questions__item__button"
        onClick={onRemoveHandler}
        role="button"
        tabIndex={0}
      >
        <img src={removeIc} alt="remove icon"/>
      </div>
    </div>
  )
}

export default memo(SimpleQuestionItem)