import {useNavigate, useParams} from "react-router-dom";
import {memo} from "react";
import {store} from "../../store";
import {changeReturnToJobPopup} from "../../store/apply-action-creators";


const HeaderApply = ({title}:{title:string}) => {
	const params=useParams()
	const navigate=useNavigate()

	const onCancel = () => {
		navigate('/job/'+params.value)
	}

	return (
		<div className="head">
			<div className="head__left">
				<p className="head__left__headline">
					{
						title
					}
				</p>
			</div>
			<div className="head__right">
				<div className="head__right__desktop">
					<button
						className="head__button head__cancel"
						onClick={() => store.dispatch(changeReturnToJobPopup(true))}
					>
						Return to job details
					</button>
				</div>
				<div className="head__right__mobile">
					<button
						className="head__prev"
						onClick={onCancel}
					>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
						     className="head__prev__icon">
							<path d="M11.2008 1.59961L4.80078 7.99961L11.2008 14.3996" stroke="#999EA5" strokeWidth="1.5"
							      strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	)
}

export default memo(HeaderApply)