import {memo, useState} from 'react'
import SimpleQuestionItem from './SimpleQuestionItem'

const plusIcon = require('../../../image/icon/green_plus_icon.svg')

const SimpleQuestions = ({setSimpleQuestion, simpleQuestion, placeholder}) => {
  const [currentInput, setCurrentInput] = useState<string>('')

  const addHandler = () => {
    if (currentInput.length > 0){
      setSimpleQuestion(prev => [...prev, { value: currentInput, id: Date.now()}])
      setCurrentInput('')
    }
  }

  return (
    <div className="simple-questions">
      <div className="simple-questions__item">
        <input
          type="text"
          className="simple-questions__item__input"
          value={currentInput}
          placeholder={placeholder}
          onChange={e => setCurrentInput(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && addHandler()}
        />
        <div
          className="simple-questions__item__button"
          onClick={addHandler}
          role="button"
          tabIndex={0}
        >
          <img src={plusIcon} alt="plus icon"/>
        </div>
      </div>
      <div className="simple-questions__list">
        {
          simpleQuestion?.map((item, index) => {
            return(
              <SimpleQuestionItem
                key={index}
                item={item}
                setSimpleQuestion={setSimpleQuestion}
                simpleQuestion={simpleQuestion}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default memo(SimpleQuestions)