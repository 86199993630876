import { store } from '../../../store'
import { changeSavedPopup } from '../../../store/action-creators'
import { Link } from '@reach/router'
import { useTypedSelector } from '../../../hook/useTypedSelector'
import { useParams } from 'react-router-dom'
import {memo} from "react";


const successImage = require('../../../image/icon/success_image.svg')

const ChangeSaved = () => {
  const initData = useTypedSelector(state => state.screen.jobDetails)
  const location = useParams()


  return (
    <div className="popup">
      <div className="popup__step">
        <div className="popup__head">
          <p className="popup__head__headline">
            Changes Saved
          </p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="popup__head__close"
            onClick={() => store.dispatch(changeSavedPopup(false))}
          >
            <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"/>
          </svg>
        </div>
        <div className="popup__body">
          <img src={successImage} alt="" className="popup__body__image"/>
          <p className="popup__body__text center">
            Changes for job {initData.jobTitle} have been applied.
          </p>
        </div>
        <div className="popup__bottom center">
          <Link
            className="popup__bottom__publish button--filled"
            to={`/job/${location.value}`}
          >
            Got it!
          </Link>
        </div>
      </div>
    </div>
  )
}

export default memo(ChangeSaved)