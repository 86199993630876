import {memo, useState} from "react";
import {IAnswer} from "../../../types/redux-types";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";

const Answer = ({item, setAnswers, id,type}:
                    { type:string,item: IAnswer, id: number, setAnswers: (value: ((item: Array<IAnswer>) => Array<IAnswer>) | Array<IAnswer>) => void }) => {

    const {
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return <div ref={setNodeRef} style={style}>
        <Memo listeners={listeners} item={item} setAnswers={setAnswers} type={type}/>
    </div>
}

export default memo(Answer)

const AnswerItem=({item,listeners,setAnswers,type})=>{
    const [input, setInput] = useState(item.name);
    const [error, setError] = useState(false);
    return <div className='application_form__answer__item'  >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...listeners}
             xmlns="http://www.w3.org/2000/svg"
             style={{width: '16px', height: '16px'}}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1.59961 8.00065C1.59961 7.63246 1.89809 7.33398 2.26628 7.33398H13.4663C13.8345 7.33398 14.1329 7.63246 14.1329 8.00065C14.1329 8.36884 13.8345 8.66732 13.4663 8.66732H2.26628C1.89809 8.66732 1.59961 8.36884 1.59961 8.00065Z"
                  fill="#C1C5CB"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1.59961 4.00065C1.59961 3.63246 1.89809 3.33398 2.26628 3.33398H13.4663C13.8345 3.33398 14.1329 3.63246 14.1329 4.00065C14.1329 4.36884 13.8345 4.66732 13.4663 4.66732H2.26628C1.89809 4.66732 1.59961 4.36884 1.59961 4.00065Z"
                  fill="#C1C5CB"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1.59961 12.0007C1.59961 11.6325 1.89809 11.334 2.26628 11.334H13.4663C13.8345 11.334 14.1329 11.6325 14.1329 12.0007C14.1329 12.3688 13.8345 12.6673 13.4663 12.6673H2.26628C1.89809 12.6673 1.59961 12.3688 1.59961 12.0007Z"
                  fill="#C1C5CB"/>
        </svg>
        {
            type==='onefromlist'&&<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={'application_form__answer__svg'}>
                <circle cx="8" cy="8" r="7.5" fill="white" stroke="#C1C5CB"/>
            </svg>
        }
        {
            type==='dropableselect'&&<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={'application_form__answer__svg'}>
                <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" stroke="#C1C5CB"/>
                <path d="M8.00093 6.5H5.70803C5.26258 6.5 5.0395 7.03857 5.35448 7.35355L7.64737 9.64645C7.84264 9.84171 8.15922 9.84171 8.35448 9.64645L10.6474 7.35355C10.9624 7.03857 10.7393 6.5 10.2938 6.5H8.00093Z" fill="#C1C5CB"/>
            </svg>
        }
        {
            type==='multiplechoice'&&<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={'application_form__answer__svg'}>
                <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" stroke="#C1C5CB"/>
            </svg>
        }
        <input className={`application_form__answer__input ${error?'error':''}`} value={input}
               onChange={(e) => {
                   setInput(e.target.value)
               }}
               onFocus={()=>error&&setError(false)}
               onBlur={() => {
                   setAnswers(prev => prev.map(x => x.id === item.id ? {id: x.id, name: input} : x))
                   input===''?setError(true):null
               }}
        />

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
             className='application_form__answer__change'
             onClick={() => setAnswers(prev => prev.filter(x => x.id !== item.id))}
        >
            <path d="M12 4L8 8M4 12L8 8M8 8L4 4L12 12" stroke="#B0BBCB" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    </div>
}
const Memo=memo(AnswerItem)
