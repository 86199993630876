import {useTypedSelector} from './useTypedSelector'

export const useCurrentPopupData = (status) => {
  const {
    jobDetails,
    requirements,
    benefits,
    aboutCompany,
    jobBoards,
    applicationForm,
    attachApplicationFom
  } = useTypedSelector(state => state.screen)

  return {
    title: jobDetails.jobTitle,
    employer: jobDetails.employer,
    consultancy: jobDetails.consultancy,
    locations: jobDetails.jobLocation,
    remoteLocation: jobDetails.remoteLocation,
    salaryMonthMin: jobDetails.salaryRangeMonth[0],
    salaryMonthMax: jobDetails.salaryRangeMonth[1],
    salaryYearMin: jobDetails.salaryRangeYear[0],
    salaryYearMax: jobDetails.salaryRangeYear[1],
    negotiable: jobDetails.negotiableSalary,
    description: jobDetails.jobDescription,
    shortDescription: jobDetails.shortJobDescription,
    numberOpenings: jobDetails.opening.label,
    jobType: jobDetails.jobType.label,
    preferableShift: jobDetails.preferableShift.label,
    positionId: jobDetails.position?.id,
    industryId: jobDetails.industryType?.id,
    functionalArea: jobDetails.functionalArea.label,
    noticePeriod: jobDetails.noticePeriod.label,
    skills: requirements.skills,
    experienceMin: requirements.experienceYears[0],
    experienceMax: requirements.experienceYears[1],
    education: requirements.education.label,
    screeningQuestions: requirements.screeningQuestions,
    benefits: benefits.benefitsList,
    aboutCompany: aboutCompany.aboutCompany,
    workflowId: aboutCompany.workflow.value || null,
    careerPortal: aboutCompany.careerPortal,
    linkedin: aboutCompany.linkedin,
    facebook: aboutCompany.facebook,
    twitter: aboutCompany.twitter,
    instagram: aboutCompany.instagram,
    careerPage: aboutCompany.careerPage,
    publicSearch: aboutCompany.publicSearch,
    jobBoards: jobBoards.integrations,
    applicationForm: attachApplicationFom ? applicationForm : [],
    status: status,
  }
}