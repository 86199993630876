import {getEnv} from "@urecruits/api";

const token: string = localStorage.getItem('token')
const config = {
	method: 'GET',
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
	}
}
const {API_RECRUITMENT} = getEnv()

export const selectSearchFuncLocation = async (searchValue) => {
	const req = await fetch(`${API_RECRUITMENT}/api/location/${searchValue}`, config)
		.then((response) => response.json())

	return req.map(item => {
		return {
			value: `${item.id}`,
			label: `${item.city}, ${item.state}`,
		}
	})
}

export const selectSearchFuncPosition = async (searchValue) => {
	const req = await fetch(`${API_RECRUITMENT}/api/position/${searchValue}`, config)
		.then((response) => response.json())

	return req
}
export const selectSearchFuncPositionLimit = async (searchValue) => {
	const req = await fetch(`${API_RECRUITMENT}/api/position/filter?${searchValue}`, config)
		.then((response) => response.json())

	return req
}
export const selectSearchFuncIndustry = async (searchValue) => {
	const req = await fetch(`${API_RECRUITMENT}/api/industry/label/${searchValue}`, config)
		.then((response) => response.json())

	return req
}