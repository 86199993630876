export enum ActionEnums{
    CHANGE_JOB_DETAILS='CHANGE_JOB_DETAILS',
    JOB_DETAILS_VALIDATE='JOB_DETAILS_VALIDATE',
    CHANGE_REQUIREMENTS='CHANGE_REQUIREMENTS',
    REQUIREMENTS_VALIDATE='REQUIREMENTS_VALIDATE',
    CHANGE_BENEFITS='CHANGE_BENEFITS',
    CHANGE_ABOUT_COMPANY='CHANGE_ABOUT_COMPANY',
    CHANGE_APPLICATION_FORM='CHANGE_APPLICATION_FORM',
    CHANGE_CURRENT_SCREEN='CHANGE_CURRENT_SCREEN',
    CHANGE_JOB_BOARDS='CHANGE_JOB_BOARDS',
    SET_APPLICATION_FORM='SET_APPLICATION_FORM',
    CHANGE_DRAFT_POPUP='CHANGE_DRAFT_POPUP',
    CHANGE_APPROVAL_POPUP='CHANGE_APPROVAL_POPUP',
    CHANGE_PUBLISH_POPUP='CHANGE_PUBLISH_POPUP',
    CHANGE_CANCEL_POPUP = "CHANGE_CANCEL_POPUP",
    CHANGE_SUBSCRIBE_POPUP = "CHANGE_SUBSCRIBE_POPUP",
    CHANGE_SAVE_TEMPLATE_POPUP = "CHANGE_SAVE_TEMPLATE_POPUP",
    CHANGE_ACTION_STATE = "CHANGE_ACTION_STATE",
    CHANGE_SAVED_POPUP = "CHANGE_SAVED_POPUP",
    CHANGE_APPLY_POPUP = "CHANGE_APPLY_POPUP",
    CHANGE_RETURN_POPUP = "CHANGE_RETURN_POPUP",
    CHANGE_SUCCESSFULLY_POPUP = "CHANGE_SUCCESSFULLY_POPUP",
    ATTACH_APPLICATION_FORM = "ATTACH_APPLICATION_FORM",
    CHANGE_WORKFLOWS_LIST = "CHANGE_WORKFLOWS_LIST",
    ABOUT_COMPANY_VALIDATE = "ABOUT_COMPANY_VALIDATE",
    CHANE_SAVE_JOB_STATUS = "CHANE_SAVE_JOB_STATUS",
    CHANE_SUBSCRIBE_JOB_STATUS = "CHANE_SUBSCRIBE_JOB_STATUS",
    CHANE_APPLY_JOB_STATUS = "CHANE_APPLY_JOB_STATUS",
}

export enum ActionState {
    ACTION_STATE_EDIT = 'Edit Job',
    ACTION_STATE_SAVE = 'Create a New Job'
}

export enum ScreenEnums{
    JOB_DETAILS='Job Details',
    REQUIREMENTS='Requirements',
    BENEFITS='Benefits',
    ABOUT_COMPANY='About Company',
    JOB_BOARDS='Job Boards',
    APPLICATION='Application Form',
}
