const MultiSelectHeadItem = ({item, setSelectedData}) => {

  const onDeleteHandler = () => {
    setSelectedData(prev => prev.filter(x => x !== item))

  }

  return (
    <div className="multi-select__head__item">
      <p className="multi-select__head__text">
        {item}
      </p>
      <svg
        className="multi-select__head__close"
        onClick={onDeleteHandler}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 4L8 8M4 12L8 8M8 8L4 4L12 12"
          stroke="#015462"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default MultiSelectHeadItem