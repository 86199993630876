import {memo, useEffect, useState} from 'react'
import SimpleQuestions from '../SimpleQuestions/SimpleQuestions'
import { store } from '../../../store'
import { changeBENEFITS, changeCurrenScreen, changeRequirements } from '../../../store/action-creators'
import { ScreenEnums } from '../../../store/redux-enums'
import { useTypedSelector } from '../../../hook/useTypedSelector'

const Benefits = () => {
  const actionState = useTypedSelector(state => state.screen.actionState)
  const popupStatus = useTypedSelector(state => state.screen.draftPopup)
  const initData = useTypedSelector(state => state.screen.benefits)

  const [simpleQuestion, setSimpleQuestion] = useState(initData.benefitsList)


  const defaultValues = () => {
    setSimpleQuestion(initData.benefitsList)
  }


  useEffect(() => {
    if (actionState === 'Edit Job') {
      store.dispatch(changeBENEFITS({
        benefitsList: simpleQuestion
      }))
    }
  }, [simpleQuestion])

  useEffect(() => {
    defaultValues()
  }, [initData])

  const moveToNextStep = () => {
    store.dispatch(changeBENEFITS({
      benefitsList: simpleQuestion
    }))
    store.dispatch(changeCurrenScreen(ScreenEnums.ABOUT_COMPANY))
  }

  const moveToPrevStep = () => {
    store.dispatch(changeBENEFITS({
      benefitsList: simpleQuestion
    }))
    store.dispatch(changeCurrenScreen(ScreenEnums.REQUIREMENTS))
  }

  //set actually data to redux
  useEffect(() => {
    if (popupStatus) {
      store.dispatch(changeBENEFITS({
        benefitsList: simpleQuestion
      }))
    }
  }, [popupStatus])

  return (
    <div className="step">
      <div className="step__head">
        <p className="step__head__headline">
          03. Benefits
        </p>
      </div>
      <div className="step__body">
        <div className="step__row">
          <div className="step__item long">
            <p className="step__item__description">
              Enter the Benefits in case if any other benefits offered for this jobs.<span> E.g.: Yearly Bonus, Employee Referral Bonus, Spot Award, Performance Incentives</span>
            </p>
            <p className="step__item__label">Benefit(s)</p>
            <SimpleQuestions
              simpleQuestion={simpleQuestion}
              setSimpleQuestion={setSimpleQuestion}
              placeholder={'Enter benefit(s)'}
            />
          </div>
        </div>
      </div>
      <div className="step__bottom">
        <button
          className="step__bottom__prev"
          onClick={moveToPrevStep}
        >
          Back
        </button>
        <button
          className="step__bottom__next button--empty"
          onClick={moveToNextStep}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default memo(Benefits)