const BoardItem = ({item, integrations, setIntegrations}) => {

	const changeHandler = () => {
		if (item.id !== 1) {
			if (integrations.find(x => x.id === item.id)) {
				setIntegrations(prev => prev.filter(x => x.id !== item.id));
			} else {
				setIntegrations(prev => [...prev, {name: item.name, id: item.id, uniqueIntegrationId: item.unique_id}]);
			}
		}
	};

	return (
		<button
			className={`boards__item ${integrations.find(x => x.id === item.id) ? "active" : ""}`}
			onClick={changeHandler}
		>
			<div className="boards__item__image">
				<img src={item.image.url} alt="board name" className="boards__item__image"/>
			</div>
			<p className="boards__item__name">{item.name}</p>
		</button>
	);
};

export default BoardItem;