import { default as ReactSelect, components } from "react-select";
import { selectCustomStyle } from "../../../styles/selectCustomStyle";
import { memo, useEffect, useState } from "react";
import CustomRange from "../CustomRange";
import rangeValidate from "../../../hook/rangeValidate";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import { store } from "../../../store";
import {
	changeCurrenScreen,
	changeJobDetails,
	jobDetailsValidate,
} from "../../../store/action-creators";
import { ScreenEnums } from "../../../store/redux-enums";
import AsyncSelect from "react-select/async";
import RichTextEditor from "../../RichTextEditor";
import { NumericFormat } from "react-number-format";
import { getEnv } from "@urecruits/api";
import { selectSearchFuncIndustry, selectSearchFuncPositionLimit } from "../../../hook/selectSearchFunc";
import { FilterWithLimitComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { selectSearchFuncLocationLimit } from '@urecruits/api';

const SALARY_YEAR_MIN = 1;
const SALARY_YEAR_MAX = 1000000;

const SALARY_MONTH_MIN = 1;
const SALARY_MONTH_MAX = 2000000;

const Option = (props: any) => {

	return (
		<div>
			<components.Option {...props} className="select-component">
				<input
					id={props.value}
					type="checkbox"
					checked={props.isSelected}
					onChange={() => null}
				/>{" "}
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				<label htmlFor={props.value}><span /></label>
				<p>{props.label}</p>
			</components.Option>
		</div>
	);
};
const { API_RECRUITMENT } = getEnv();

const callbackFunc = async (searchValue) => {
	const token: string = localStorage.getItem("token");

	const req = await fetch(
		`${API_RECRUITMENT}/api/location/${searchValue}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	).then((response) => response.json());
	return req.map(item => {
		return {
			value: `${item.city}, ${item.state}`,
			label: `${item.city}, ${item.state}`,
			id: item.id,
		};
	});
};

const JobDetails = () => {
	const actionState = useTypedSelector(state => state.screen.actionState);
	const popupStatus = useTypedSelector(state => state.screen.draftPopup);
	const initData = useTypedSelector(state => state.screen.jobDetails);
	const jobDetailsValidateValue = useTypedSelector(state => state.screen.jobDetailsValidate);

	//options list
	const [jobTitle, setJobTitle] = useState(initData.jobTitle);
	const [employer, setEmployer] = useState(initData.employer);
	const [consultancy, setConsultancy] = useState(initData.consultancy);
	const [jobLocation, setJobLocation] = useState(initData.jobLocation);

	const [jobDescription, setJobDescription] = useState(initData.jobDescription);
	const [shortJobDescription, setShortJobDescription] = useState(initData.shortJobDescription);
	const [openings, setOpening] = useState(initData.opening);
	const [jobType, setJobType] = useState(initData.jobType);
	const [preferableShift, setPreferableShift] = useState(initData.preferableShift);
	const [position, setPosition] = useState(initData.position);
	const [industryType, setIndustryType] = useState(initData.industryType);
	const [functionalArea, setFunctionalArea] = useState(initData.functionalArea);
	const [noticePeriod, setNoticePeriod] = useState(initData.noticePeriod);

	//checkbox data
	const [consultancyCheckbox, setConsultancyCheckbox] = useState<boolean>(false);
	const [remoteLocationCheckbox, setRemoteLocationCheckbox] = useState(initData.remoteLocation);
	const [negotiableCheckbox, setNegotiableCheckbox] = useState(initData.negotiableSalary);

	//salary month data
	const [salaryMonthValue, setSalaryMonthValue] = useState(initData.salaryRangeMonth);
	const [salaryMonthPrevData, setSalaryMonthPrevData] = useState(initData.salaryRangeMonth);
	const [salaryMonthMinError, setSalaryMonthMinError] = useState(false);
	const [salaryMonthMaxError, setSalaryMonthMaxError] = useState(false);

	//salary year range
	const [salaryYearValue, setSalaryYearValue] = useState(initData.salaryRangeYear);
	const [salaryYearPrevData, setSalaryYearPrevData] = useState(initData.salaryRangeYear);
	const [salaryYearMinError, setSalaryYearMinError] = useState(false);
	const [salaryYearMaxError, setSalaryYearMaxError] = useState(false);

	const [jobTitleValidate, setJobTitleValidate] = useState(false);
	const [jobLocationValidate, setJobLocationValidate] = useState(false);
	const [employerValidate, setEmployerValidate] = useState(false);
	const [jobDescriptionValidate, setJobDescriptionValidate] = useState(false);
	const [shortJobDescriptionValidate, setShortJobDescriptionValidate] = useState(false);
	const [jobTypeValidate, setJobTypeValidate] = useState(false);
	const [preferableShiftValidate, setPreferableShiftValidate] = useState(false);
	const [positionValidate, setPositionValidate] = useState(false);
	const [industryTypeValidate, setIndustryTypeValidate] = useState(false);
	const [functionalAreaValidate, setFunctionalAreaValidate] = useState(false);
	const [openingsValidate, setOpeningsValidate] = useState(false);
	const [openingsRangeValidation, setOpeningsRangeValidation] = useState(false);
	const [noticePeriodValidate, setNoticePeriodValidate] = useState(false);
	const [consultancyValidate, setConsultancyValidate] = useState(false);


	const [stepValidate, setStepValidate] = useState(false);

	const defaultValues = () => {
		setJobTitle(initData.jobTitle);
		setEmployer(initData.employer);
		setConsultancy(initData.consultancy);
		setJobLocation(initData.jobLocation);
		setJobDescription(initData.jobDescription);
		setOpening(initData.opening);
		setJobType(initData.jobType);
		setPreferableShift(initData.preferableShift);
		setPosition(initData.position);
		setIndustryType(initData.industryType);
		setFunctionalArea(initData.functionalArea);
		setNoticePeriod(initData.noticePeriod);
		setRemoteLocationCheckbox(initData.remoteLocation);
		setNegotiableCheckbox(initData.negotiableSalary);
		setSalaryMonthValue(initData.salaryRangeMonth);
		setSalaryMonthPrevData(initData.salaryRangeMonth);
		setSalaryYearValue(initData.salaryRangeYear);
		setSalaryYearPrevData(initData.salaryRangeYear);
		setShortJobDescription(initData.shortJobDescription);
	};


	useEffect(() => {
		defaultValues();
	}, [initData]);


	useEffect(() => {
		if (actionState === "Edit Job") {
			setDataToRedux();
		}
	}, [jobTitle, employer, consultancy, jobLocation, remoteLocationCheckbox, salaryMonthValue, salaryYearValue, negotiableCheckbox, jobDescription, openings, preferableShift, industryType, functionalArea, noticePeriod, shortJobDescription]);

	const setDataToRedux = () => {
		store.dispatch(changeJobDetails({
			jobTitle: jobTitle,
			employer: employer,
			consultancy: consultancy,
			jobLocation: jobLocation,
			remoteLocation: remoteLocationCheckbox,
			salaryRangeMonth: salaryMonthValue,
			salaryRangeYear: salaryYearValue,
			negotiableSalary: negotiableCheckbox,
			jobDescription: jobDescription,
			opening: openings,
			jobType: jobType,
			preferableShift: preferableShift,
			position: position,
			industryType: industryType,
			functionalArea: functionalArea,
			noticePeriod: noticePeriod,
			shortJobDescription: shortJobDescription,
		}));
	};

	//TODO: need to research best validate decision
	const moveToNextStep = () => {
		jobTitle.length === 0 || jobLocation.length === 0 || employer.length === 0 || jobDescription.length === 0 || shortJobDescription.length === 0 || jobType?.value?.length === 0 || preferableShift?.value?.length === 0 || position?.value?.length === 0 || industryType?.value?.length === 0 || functionalArea?.value?.length === 0 || salaryMonthMaxError || salaryMonthMinError || salaryYearMinError || salaryYearMaxError ? setStepValidate(true) : setStepValidate(false);
		console.log({ tileerror:jobTitle.length === 0 ,location:jobLocation.length === 0 ,empployer:employer.length === 0 ,jobdescription: jobDescription.length === 0 , shortjob:shortJobDescription.length === 0 , jobtype:jobType?.value?.length === 0 , shift:preferableShift?.value?.length === 0 , position:position?.value?.length === 0 , industry:industryType?.value?.length === 0 , fa:functionalArea?.value?.length === 0 , salaryMonthMaxError ,salaryMonthMinError , salaryYearMinError , salaryYearMaxError })
		//error style trigger
		jobTitle.length === 0 && (setJobTitleValidate(true));
		jobLocation.length === 0 && !remoteLocationCheckbox && (setJobLocationValidate(true));
		employer.length === 0 && (setEmployerValidate(true));
		jobDescription.length === 0 && (setJobDescriptionValidate(true));
		shortJobDescription.length === 0 && (setShortJobDescriptionValidate(true));
		!jobType.value && (setJobTypeValidate(true));
		!openings.value && (setOpeningsValidate(true));
		!noticePeriod.value && (setNoticePeriodValidate(true));
		!preferableShift.value && (setPreferableShiftValidate(true));
		!position?.value && (setPositionValidate(true));
		!industryType?.value && (setIndustryTypeValidate(true));
		!functionalArea.value && (setFunctionalAreaValidate(true));
		consultancy.length === 0 && consultancyCheckbox && setConsultancyValidate(true)
		//check validate
		if ((consultancy.length !== 0 || !consultancyCheckbox) && jobTitle.length !== 0 && (jobLocation.length !== 0 || remoteLocationCheckbox) && employer.length !== 0 && jobType.value && openings.value && !openingsRangeValidation && noticePeriod.value && preferableShift.value && position.value && industryType.value && functionalArea.value && jobDescription.length !== 0 && shortJobDescription.length !== 0 && !salaryMonthMaxError && !salaryMonthMaxError && !salaryYearMaxError && !salaryYearMinError) {
			setDataToRedux();
			store.dispatch(jobDetailsValidate(true));
			store.dispatch(changeCurrenScreen(ScreenEnums.REQUIREMENTS));
		}
	};

	//set actually data to redux
	useEffect(() => {
		if (popupStatus) {
			setDataToRedux();
		}
	}, [popupStatus]);


	//publish/approval button validate logic
	useEffect(() => {
		if (!!jobTitle && !!jobLocation.length && !!jobType.value && !!employer && !!preferableShift.value && !!position?.value && !!position?.value && !!openings.value && !!noticePeriod.value && !!functionalArea?.value && !!shortJobDescription && !!jobDescription) {
			store.dispatch(jobDetailsValidate(true));
		} else {
			store.dispatch(jobDetailsValidate(false));
		}
	}, [jobTitle, employer, jobType, preferableShift, position, industryType, functionalArea, jobLocation, jobDescription, shortJobDescription]);

	//trigger range validate when use <CustomRange/> component
	useEffect(() => {
		rangeValidate(salaryMonthValue[0], salaryMonthValue, SALARY_MONTH_MIN, SALARY_MONTH_MAX, "min") ? setSalaryMonthMinError(true) : setSalaryMonthMinError(false);
		rangeValidate(salaryMonthValue[1], salaryMonthValue, SALARY_MONTH_MIN, SALARY_MONTH_MAX, "max") ? setSalaryMonthMaxError(true) : setSalaryMonthMaxError(false);

	}, salaryMonthValue);

	//trigger range validate when use <CustomRange/> component
	useEffect(() => {
		rangeValidate(salaryYearValue[0], salaryYearValue, SALARY_YEAR_MIN, SALARY_YEAR_MAX, "min") ? setSalaryYearMinError(true) : setSalaryYearMinError(false);
		rangeValidate(salaryYearValue[1], salaryYearValue, SALARY_YEAR_MIN, SALARY_YEAR_MAX, "max") ? setSalaryYearMaxError(true) : setSalaryYearMaxError(false);

	}, salaryYearValue);


	useEffect(() => {
		if (openings.value && +openings.value < 1 || +openings.value > 50) {
			setOpeningsRangeValidation(true)
		}
	}, [openingsRangeValidation, openings])

	return (
		// @ts-ignore
		<div className="step">
			<div className="step__head">
				<p className="step__head__headline">
					01. Job Details
				</p>
			</div>
			<div className="step__body">
				<div className="step__row">
					<div className={jobTitleValidate ? "step__item error" : "step__item"}>
						<p className="step__item__label">Job Title<span> *</span></p>
						<input
							type="text"
							className="step__item__input"
							name="jobTitle"
							id="jobTitle"
							placeholder="Enter job title"
							value={jobTitle}
							onChange={e => {
								e.target.value.length === 0 ? setJobTitleValidate(true) : setJobTitleValidate(false);
								setJobTitle(e.target.value);
							}}
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
					<div className={employerValidate ? "step__item error" : "step__item"}>
						<p className="step__item__label">Employer<span> *</span></p>
						<input
							type="text"
							className="step__item__input"
							name="employer"
							id="employer"
							value={employer}
							onChange={e => {
								e.target.value.length === 0 ? setEmployerValidate(true) : setEmployerValidate(false);
								setEmployer(e.target.value);
							}}
							placeholder="Enter employer"
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
				</div>
				<div className="step__row">
					<div className="step__item">
						<div className="custom-checkbox bottom">
							<input
								type="checkbox"
								className="custom-checkbox__input"
								id="consultancy"
								name="consultancy"
								checked={consultancyCheckbox}
								onChange={(e) => {
									setConsultancyCheckbox(!consultancyCheckbox)
									setConsultancyValidate(false)
								}}
							/>
							<label htmlFor="consultancy" className="custom-checkbox__label">
								<div className="custom-checkbox__item">
									<span />
								</div>
								<p className="custom-checkbox__text">Consultancy</p>
							</label>
						</div>
					</div>
					<div className={`${consultancyValidate ? 'step__item error' : 'step__item'}`}>
						<p className="step__item__label">Consultancy
							{
								consultancyCheckbox && <span> *</span>
							}
						</p>
						<input
							type="text"
							className={`step__item__input ${!consultancyCheckbox ? "disable" : ""}`}
							placeholder={`${!consultancyCheckbox ? "Not indicated" : "Enter consultancy name"}`}
							disabled={!consultancyCheckbox}
							name="consultancy"
							id="consultancy"
							value={consultancy}
							onChange={e => {
								setConsultancy(e.target.value)
								e.target.value.length !== 0 && setConsultancyValidate(false)
							}}
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
				</div>
				<div className="step__row">
					<div className={jobLocationValidate ? "step__item long  error" : "step__item long"}>
						<p className="step__item__label">Job Location
							{
								!remoteLocationCheckbox && <span> *</span>
							}</p>
						<FilterWithLimitComponent
							filter={{
								value: jobLocation, 
								onChangeValue: (option) => {
									setJobLocation(option);
									option.length === 0 && !remoteLocationCheckbox ? setJobLocationValidate(true) : setJobLocationValidate(false);
								}
							}}
							placeHolder="Select Location"
							setLimitSearchfunction={selectSearchFuncLocationLimit}
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
				</div>
				<div className="step__row">
					<div className="step__item">
						<div className="custom-checkbox salary">
							<input
								type="checkbox"
								className="custom-checkbox__input"
								id="remote"
								name="remote"
								checked={remoteLocationCheckbox}
								onChange={(e) => setRemoteLocationCheckbox(!remoteLocationCheckbox)}
							/>
							<label htmlFor="remote" className="custom-checkbox__label">
								<div className="custom-checkbox__item">
									<span></span>
								</div>
								<p className="custom-checkbox__text">Remote location available</p>
							</label>
						</div>
					</div>
				</div>
				<div className="step__row">
					<div className="step__item  range-inner">
						<p className="step__item__label gray">Salary Range<span> (per year)</span>
						</p>
						<div className="step__range">
							<div className="step__range">
								<div className="step__range__head">
									<div className="step__range__left">
										<div className={`step__range__inner  ${salaryYearMinError ? "error" : ""}`}>
											<span className="step__range__currency">$</span>
											<NumericFormat
												value={salaryYearValue[0]}
												onValueChange={(values) => {
													const validateResult = rangeValidate(values.floatValue, salaryYearValue, SALARY_YEAR_MIN, SALARY_YEAR_MAX, "min");
													if (validateResult) {
														setSalaryYearMinError(true);
													} else {
														setSalaryYearMinError(false);
														setSalaryYearPrevData((prev) => [values.floatValue, prev[1]]);
														setSalaryMonthPrevData((prev) => [Math.floor(values.floatValue / 12), prev[1]]);
													}
													setSalaryYearValue((prev) => [values.floatValue, prev[1]]);
													setSalaryMonthValue((prev) => [Math.floor(values.floatValue / 12), prev[1]]);
												}}
												className="step__range__input"
												thousandSeparator=","
											/>
										</div>
									</div>
									<div className="step__range__right">
										<div className={`step__range__inner  ${salaryYearMaxError ? "error" : ""}`}>
											<span className="step__range__currency">$</span>
											<NumericFormat
												value={salaryYearValue[1]}
												onValueChange={(values) => {
													const validateResult = rangeValidate(values.floatValue, salaryYearValue, SALARY_YEAR_MIN, SALARY_YEAR_MAX, "max");
													if (validateResult) {
														
														setSalaryYearMaxError(true);
													} else {
														setSalaryYearMaxError(false);
														setSalaryYearPrevData((prev) => [prev[0], values.floatValue]);
														setSalaryMonthPrevData((prev) => [prev[0], Math.floor(values.floatValue / 12)]);
													}
													setSalaryYearValue((prev) => [prev[0], values.floatValue]);
													setSalaryMonthValue((prev) => [prev[0], Math.floor(values.floatValue / 12)]);
												}}
												className="step__range__input"
												thousandSeparator=","
											/>
										</div>
									</div>
								</div>
								<div className="step__range__body">
									<CustomRange
										values={salaryYearMaxError || salaryYearMinError ? salaryYearPrevData : salaryYearValue}
										setValues={values => {
											setSalaryYearValue((prev) => [values[0], values[1]]);
											setSalaryMonthValue((prev) => [Math.floor(values[0] / 12), Math.floor(values[1] / 12)]);
										}}
										setPrevData={values => {
											setSalaryYearPrevData((prev) => [values[0], values[1]]);
											setSalaryMonthPrevData((prev) => [Math.floor(values[0] / 12), Math.floor(values[1] / 12)]);
										}}
										maxValue={SALARY_YEAR_MAX}
										minValue={SALARY_YEAR_MIN}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="step__item">
					<div className="custom-checkbox negotiable">
						<input
							type="checkbox"
							className="custom-checkbox__input"
							id="negotiable"
							name="negotiable"
							checked={negotiableCheckbox}
							onChange={(e) => setNegotiableCheckbox(!negotiableCheckbox)}
						/>
						<label htmlFor="negotiable" className="custom-checkbox__label">
							<div className="custom-checkbox__item">
								<span></span>
							</div>
							<p className="custom-checkbox__text">Negotiable</p>
						</label>
					</div>
				</div>
				<div className="step__row">
					<div className={jobDescriptionValidate ? "step__item long  error" : "step__item long "}>
						<p className="step__item__label">Job Description<span> *</span></p>
						<RichTextEditor
							value={jobDescription}
							setValue={setJobDescription}
							placeholder="Write job description"
							validateFlag={true}
							setValidate={setJobDescriptionValidate}
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
				</div>
				<div className="step__row">
					<div className={shortJobDescriptionValidate ? "step__item long  error" : "step__item long "}>
						<p className="step__item__label">Short Job Description<span> *</span></p>
						<input
							type="text"
							className="step__item__input"
							name="shortDescription"
							id="shortDescription"
							value={shortJobDescription}
							onChange={e => {
								e.target.value.length === 0 ? setShortJobDescriptionValidate(true) : setShortJobDescriptionValidate(false);
								setShortJobDescription(e.target.value);
							}}
							placeholder="Enter short job description"
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
				</div>
				<div className="step__row">
					<div className={`step__item ${(openingsRangeValidation || openingsValidate) ? "error" : ""}`}>
						<p className="step__item__label">No of Openings<span> *</span></p>
						<input
							type="number"
							value={openings.value}
							className="step__item__input"
							max={50}
							min={0}

							onChange={(e: any) => {
								setOpening({ value: e.target.value, label: e.target.value });
								if (+e.target.value > 0 && +e.target.value <= 50) {
									setOpeningsRangeValidation(false)
								}
								setOpeningsValidate(false);
							}} />
						<p className="error-message">
							{openingsRangeValidation ? "No. of opening must be between 0 and 50." : "Required field"}
						</p>
					</div>
					<div className={`step__item ${jobTypeValidate ? "error" : ""} `}>
						<p className="step__item__label">Job Type<span> *</span></p>
						<ReactSelect
							options={jobTypeOption}
							closeMenuOnSelect={true}
							hideSelectedOptions={false}
							onChange={(currentValue: any) => {
								setJobType({ value: currentValue.value, label: currentValue.label });
								setJobTypeValidate(false);
							}}
							value={jobTypeOption.find(x => x.label === jobType.label) ? jobTypeOption.find(x => x.label === jobType.label) : ""}
							placeholder={`Select job type`}
							styles={selectCustomStyle}
							id="jobType"
							instanceId="jobType"
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
				</div>
				<div className="step__row">
					<div className={`step__item ${preferableShiftValidate ? "error" : ""} `}>
						<p className="step__item__label">Preferable Shift<span> *</span></p>
						<ReactSelect
							options={preferableShiftOption}
							closeMenuOnSelect={true}
							hideSelectedOptions={false}
							onChange={(currentValue: any) => {
								setPreferableShift({ value: currentValue.value, label: currentValue.label });
								setPreferableShiftValidate(false)
							}}
							value={preferableShiftOption.find(x => x.label === preferableShift.label) ? preferableShiftOption.find(x => x.label === preferableShift.label) : ""}
							placeholder={`Select preferable shift`}
							styles={selectCustomStyle}
							id="shiftPref"
							instanceId="shiftPref"
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
					<div className={`step__item ${positionValidate ? "error" : ""} `}>
						<p className="step__item__label">Position<span> *</span></p>
						<FilterWithLimitComponent filter={{
							value: position, onChangeValue: (option: any) => {
								setPosition(option);
								option.label?.length === 0 ? setPositionValidate(true) : setPositionValidate(false);
							}
						}}
							placeHolder="Select a Position"
							isMulti={false}
							setLimitSearchfunction={selectSearchFuncPositionLimit}
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
				</div>
				<div className="step__row">
					<div className={`step__item ${industryTypeValidate ? "error" : ""} `}>
						<p className="step__item__label">Industry Type<span> *</span></p>
						<AsyncSelect
							cacheOptions
							loadOptions={(inputValue) => inputValue.length > 0 ? selectSearchFuncIndustry(inputValue) : selectSearchFuncIndustry("")}
							defaultOptions
							closeMenuOnSelect={true}
							hideSelectedOptions={false}
							value={industryType}
							placeholder={`Select industry type`}
							onChange={(option: any) => {
								setIndustryType(option);
								option.label?.length === 0 ? setIndustryTypeValidate(true) : setIndustryTypeValidate(false);
							}}
							id="industry"
							instanceId="industry"
							styles={selectCustomStyle}
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
					<div className={`step__item ${functionalAreaValidate ? "error" : ""} `}>
						<p className="step__item__label">Functional Area<span> *</span></p>
						<ReactSelect
							options={functionAreaOption}
							closeMenuOnSelect={true}
							hideSelectedOptions={false}
							onChange={(currentValue: any) => {
								setFunctionalArea({ value: currentValue.value, label: currentValue.label });
								setFunctionalAreaValidate(false)
							}}
							value={functionAreaOption.find(x => x.label === functionalArea.label) ? functionAreaOption.find(x => x.label === functionalArea.label) : ""}
							placeholder={`Select functional area`}
							styles={selectCustomStyle}
							id="functionalArea"
							instanceId="functionalArea"
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
				</div>
				<div className="step__row">
					<div className={`step__item ${noticePeriodValidate ? "error" : ""} `}>
						<p className="step__item__label">Notice Period<span> *</span></p>
						<ReactSelect
							options={noticePeriodOption}
							closeMenuOnSelect={true}
							hideSelectedOptions={false}
							onChange={(currentValue: any) => {
								setNoticePeriod({ value: currentValue.value, label: currentValue.label });
								setNoticePeriodValidate(false);
							}}
							value={noticePeriodOption.find(x => x.label === noticePeriod.label) ? noticePeriodOption.find(x => x.label === noticePeriod.label) : ""}
							placeholder={`Select notice period`}
							styles={selectCustomStyle}
							id="noticePeriod"
							instanceId="noticePeriod"
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
				</div>
			</div>
			<div className="step__bottom">
				<button
					className="step__bottom__next button--empty"
					onClick={moveToNextStep}
				>
					Next
				</button>
				{
					stepValidate && (
						<p className="error-message">
							Please fill in the required fields
						</p>
					)
				}
			</div>
		</div>
	);
};

const functionAreaOption = [
	{ value: "Executive Management", label: "Executive Management" },
	{ value: "Human Resources (HR)", label: "Human Resources (HR)" },
	{ value: "Finance and Accounting", label: "Finance and Accounting" },
	{ value: "Human resources", label: "Human resources" },
	{ value: "Sales and Marketing", label: "Sales and Marketing" },
	{ value: "Information Technology (IT)", label: "Information Technology (IT)" },
	{ value: "Research and Development (R&D)", label: "Research and Development (R&D)" },
	{ value: "Customer Service", label: "Customer Service" },
	{ value: "Legal and Compliance", label: "Legal and Compliance" },
	{ value: "Procurement and Purchasing", label: "Procurement and Purchasing" },
	{ value: "Public Relations (PR)", label: "Public Relations (PR)" },
	{ value: "Quality Assurance (QA)", label: "Quality Assurance (QA)" },
	{ value: "Facilities Management", label: "Facilities Management" },
	{ value: "Strategic Planning", label: "Strategic Planning" },
	{ value: "Training and Development", label: "Training and Development" },
	{ value: "Internal Audit", label: "Internal Audit" },
	{ value: "Investor Relations", label: "Investor Relations" },
];
const jobTypeOption = [
	{ value: "Full-Time Employees", label: "Full-Time Employees" },
	{ value: "Part-Time Employees", label: "Part-Time Employees" },
	{ value: "Temporary Employees", label: "Temporary Employees" },
	{ value: "Seasonal Employees", label: "Seasonal Employees" },
	{ value: "Independent Contractors", label: "Independent Contractors" },
	{ value: "Freelancers", label: "Freelancers" },
	{ value: "Temporary workers", label: "Temporary workers" },
	{ value: "Consultants", label: "Consultants" },
];
const preferableShiftOption = [
	{ value: "General Shift", label: "General Shift" },
	{ value: "Morning Shift", label: "Morning Shift" },
	{ value: "Afternoon Shift", label: "Afternoon Shift" },
	{ value: "Evening Shift", label: "Evening Shift" },
	{ value: "Night Shift", label: "Night Shift" },
	{ value: "Flexible Shift", label: "Flexible Shift" },
];
const noticePeriodOption = [
	{ value: "Immediately", label: "Immediately" },
	{ value: "Within 15 days", label: "Within 15 days" },
	{ value: "1 Month", label: "1 Month" },
	{ value: "2 Months", label: "2 Month" },
	{ value: "3 Months", label: "3 Month" },
	{ value: "5 Months", label: "4 Month" },
];

export default memo(JobDetails);