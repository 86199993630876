import MultiSelect from '../MultiSelect/MultiSelect'
import {memo, useEffect, useState} from 'react'
import axios from 'axios'
import rangeValidate from '../../../hook/rangeValidate'
import { default as ReactSelect } from 'react-select'
import { selectCustomStyle } from '../../../styles/selectCustomStyle'
import SimpleQuestions from '../SimpleQuestions/SimpleQuestions'
import { store } from '../../../store'
import {
  changeCurrenScreen,
  changeRequirements,
  requirementsValidate
} from '../../../store/action-creators'
import { ScreenEnums } from '../../../store/redux-enums'
import { useTypedSelector } from '../../../hook/useTypedSelector'
import CustomRange from '../CustomRange'
import { selectCustomErrorStyle } from '../../../styles/selectCustomErrorStyle'
import {getEnv} from "@urecruits/api";

const EXPERIENCE_MIN = 1
const EXPERIENCE_MAX = 50

const Requirements = () => {
  const {API_RECRUITMENT} = getEnv()
  const actionState = useTypedSelector(state => state.screen.actionState)
  const popupStatus = useTypedSelector(state => state.screen.draftPopup)
  const initData = useTypedSelector(state => state.screen.requirements)
  const requirementsValidateValue = useTypedSelector(state => state.screen.requirementsValidate)

  const [skillsList, setSkillsList] = useState([])
  const [searchParams, setSearchParams] = useState<string>('')

  const [skills, setSkills] = useState(initData.skills)
  //experience range
  const [experienceValue, setExperienceValue] = useState(initData.experienceYears)
  const [experiencePrevData, setExperiencePrevData] = useState(initData.experienceYears)
  const [experienceMinError, setExperienceMinError] = useState(false)
  const [experienceMaxError, setExperienceMaxError] = useState(false)

  const [education, setEducation] = useState(initData.education)
  const [simpleQuestion, setSimpleQuestion] = useState(initData.screeningQuestions)

  //validate
  const [skillsValidate, setSkillsValidate] = useState(false)
  const [educationValidate, setEducationValidate] = useState(false)
  const [stepValidate, setStepValidate] = useState(false)

  const defaultValues = () => {
    setSkills(initData.skills)
    setExperienceValue(initData.experienceYears)
    setEducation(initData.education)
    setSimpleQuestion(initData.screeningQuestions)
  }

  useEffect(() => {
    defaultValues()
  }, [initData])


  useEffect(() => {
    (async () => {
      try {
        await axios.get(`${API_RECRUITMENT}/api/skills/${searchParams}`).then(res => setSkillsList(res.data))
      } catch (e) {
        console.error('Fetch error', e)
      }
    })()

  }, [searchParams])


  useEffect(() => {
    if (actionState === 'Edit Job') {
      store.dispatch(changeRequirements({
        skills: skills,
        experienceYears: experienceValue,
        education: education,
        screeningQuestions: simpleQuestion
      }))
    }
  }, [skills, experienceValue, education, simpleQuestion])

  const setDataToRedux = () => {
    store.dispatch(changeRequirements({
      skills: skills,
      experienceYears: experienceValue,
      education: education,
      screeningQuestions: simpleQuestion
    }))
  }

  const moveToNextStep = () => {
    skills?.length === 0 || education?.label.length === 0 || experienceMinError || experienceMaxError ? setStepValidate(true) : setStepValidate(false)

    //error style trigger
    skills.length === 0 && (setSkillsValidate(true))
    education.label.length === 0 && (setEducationValidate(true))

    //check validate
    if (skills.length !== 0 && education.label.length !== 0 && !experienceMinError && !experienceMaxError) {
      setDataToRedux()
      store.dispatch(requirementsValidate(true))
      store.dispatch(changeCurrenScreen(ScreenEnums.BENEFITS))
    }
  }

  const moveToPrevStep = () => {
    setDataToRedux()
    store.dispatch(changeCurrenScreen(ScreenEnums.JOB_DETAILS))
  }

  //set actually data to redux
  useEffect(() => {
    if (popupStatus) {
      setDataToRedux()
    }
  }, [popupStatus])

  //publish/approval validate logic
  useEffect(() => {
    (skills.length !== 0 && education.label.length !== 0) && setStepValidate(false)

    if ((skills.length === 0 || education.label.length === 0) && requirementsValidateValue) {
      store.dispatch(requirementsValidate(false))
    }

    if (skills.length !== 0 && education.label.length !== 0 && !requirementsValidateValue) {
      store.dispatch(requirementsValidate(true))
    }

  }, [skills, education])

  return (
    <div className="step">
      <div className="step__head">
        <p className="step__head__headline">
          02. Requirements
        </p>
      </div>
      <div className="step__body">
        <div className="step__row">
          <div className={skillsValidate ? 'step__item long error' : 'step__item long'}>
            <p className="step__item__description">
              Here you need specify the any skills (programs, soft/hard skills) that candidate should have. Separate
              each skill with an enter
            </p>
            <p className="step__item__label">Skill(s)<span> *</span></p>
            <MultiSelect
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              data={skillsList}
              setSelectedData={setSkills}
              selectedData={skills}
              setValidate={setSkillsValidate}
            />
            <p className="error-message">
              Required field
            </p>
          </div>
        </div>
        <div className="step__row">
          <div className="step__item long range-inner">
            <p className="step__item__label gray">Experience<span> (years) <span className="green-star">*</span></span>
            </p>
            <div className="step__range">
              <div className="step__range">
                <div className="step__range__head">
                  <div className="step__range__left">
                    <div className={`step__range__inner small ${experienceMinError ? 'error' : ''}`}>
                      <input
                        type="text"
                        className="step__range__input"
                        value={experienceValue[0]}
                        onChange={(e) => {
                          const currentValue = e.target.value === '' ? 1 : parseInt(e.target.value)

                          const validateResult = rangeValidate(currentValue, experienceValue, EXPERIENCE_MIN, EXPERIENCE_MAX, 'min')
                          if (validateResult) {
                            setExperienceMinError(true)
                          } else {
                            setExperienceMinError(false)
                            setExperiencePrevData((prev) => [currentValue, prev[1]])
                          }
                          setExperienceValue((prev) => [currentValue, prev[1]])
                        }}
                      />
                    </div>
                  </div>
                  <div className="step__range__right">
                    <div className={`step__range__inner small ${experienceMaxError ? 'error' : ''}`}>
                      <input
                        type="text"
                        className="step__range__input"
                        value={experienceValue[1]}
                        onChange={(e) => {
                          const currentValue = e.target.value === '' ? 1 : parseInt(e.target.value)

                          const validateResult = rangeValidate(currentValue, experienceValue, EXPERIENCE_MIN, EXPERIENCE_MAX, 'max')
                          if (validateResult) {
                            setExperienceMaxError(true)
                          } else {
                            setExperienceMaxError(false)
                            setExperiencePrevData((prev) => [prev[0], currentValue])
                          }

                          setExperienceValue((prev) => [prev[0], currentValue])
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="step__range__body">
                  <CustomRange
                    values={experienceMaxError || experienceMinError ? experiencePrevData : experienceValue}
                    setPrevData={setExperiencePrevData}
                    setValues={setExperienceValue}
                    maxValue={EXPERIENCE_MAX}
                    minValue={EXPERIENCE_MIN}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="step__row">
          <div className={educationValidate ? 'step__item error' : 'step__item '}>
            <p className="step__item__label">Education<span> *</span></p>
            <ReactSelect
              options={educationOption}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(currentValue: any) => {
                setEducation({ value: currentValue.value, label: currentValue.label })
                !currentValue ? setEducationValidate(true) : setEducationValidate(false)
              }}
              value={educationOption.find(x => x.label === education.label) ? educationOption.find(x => x.label === education.label) : ''}
              placeholder={`Select required education`}
              styles={educationValidate ? selectCustomErrorStyle : selectCustomStyle}
              id="education"
              instanceId="education"
            />
            <p className="error-message">
              Required field
            </p>
          </div>
        </div>
        {/*//TODO: when we have logic display this*/}
        {/*<div className="step__row">*/}
        {/*  <div className="step__item long">*/}
        {/*    <p className="step__item__description">*/}
        {/*      These questions will be answered while the candidate is applying for the job. <span>E.g.: Does the candidate have relevant experience in ..?</span>*/}
        {/*    </p>*/}
        {/*    <p className="step__item__label">Screening Questions</p>*/}
        {/*    <SimpleQuestions*/}
        {/*      simpleQuestion={simpleQuestion}*/}
        {/*      setSimpleQuestion={setSimpleQuestion}*/}
        {/*      placeholder={'Enter screening question'}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div className="step__bottom">
        <button
          className="step__bottom__prev"
          onClick={moveToPrevStep}
        >
          Back
        </button>
        <button
          className="step__bottom__next button--empty"
          onClick={moveToNextStep}
        >
          Next
        </button>
        {
          stepValidate && (
            <p className="error-message">
              Please fill in the required fields
            </p>
          )
        }
      </div>
    </div>
  )
}

const educationOption = [
  {
    value: 'High School',
    label: 'High School'
  },
  {
    value: 'Associate degree',
    label: 'Associate degree'
  },
  {
    value: 'Bachelor\'s degree',
    label: 'Bachelor\'s degree'
  },
  {
    value: 'Master\'s degree',
    label: 'Master\'s degree'
  },
  {
    value: 'Doctorate degree',
    label: 'Doctorate degree'
  },
  {
    value: 'Professional degree',
    label: 'Professional degree'
  },
]

export default memo(Requirements)