import { memo, useEffect, useState } from 'react'
import MultiSelectListItem from './MultiSelectListItem'
import MultiSelectHeadItem from './MultiSelectHeadItem'
import axios from 'axios'
import {getEnv} from "@urecruits/api";

const MultiSelect = ({ data, searchParams, setSearchParams, selectedData, setSelectedData, setValidate }) => {
  const {API_RECRUITMENT} = getEnv()
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchParams(inputValue)
    }, 500)

    return () => clearTimeout(timer)
  }, [inputValue])

  const onCreateNewSkill = (e) => {
    e.preventDefault()

    const token: string = localStorage.getItem('token')
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }
    axios.post(`${API_RECRUITMENT}/api/skills`, {
      name: searchParams
    }, config).then((res) => {
      if (res.status == 201) {
        setSelectedData(prev => [...prev, res.data.name])
        setSearchParams('')
        setInputValue('')
      }
    })
  }

  return (
    <div className="multi-select">
      <div className="multi-select__head">
        {
          selectedData?.map((item, index) => {
            return (
              <MultiSelectHeadItem
                key={index + item}
                item={item}
                setSelectedData={setSelectedData}
              />
            )
          })
        }
        <input
          type="text"
          className="multi-select__head__input"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          placeholder={selectedData?.length === 0 ? 'Enter skill(s)' : ''}
        />
      </div>
      <div className="multi-select__list">
        <div className="multi-select__list__inner">
          {
            data?.map((item, index) => {
              return (
                <MultiSelectListItem
                  key={index + item.name}
                  item={item}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  setValidate={setValidate}
                  setInputValue={setInputValue}
                />
              )
            })
          }
          {
            data.length === 0 && searchParams.length > 0 && (
              <form
                className="multi-select__form"
                onSubmit={(e) => onCreateNewSkill(e)}
              >
                <div className="multi-select__head__item">
                  <p className="multi-select__head__text">
                    {searchParams}
                  </p>
                  <svg
                    className="multi-select__head__close"
                    onClick={() => {
                      setSearchParams('')
                      setInputValue('')
                    }}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 4L8 8M4 12L8 8M8 8L4 4L12 12"
                      stroke="#015462"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <button className="multi-select__form__submit" type="submit">create</button>
              </form>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default memo(MultiSelect)