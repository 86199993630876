import { useEffect, useState } from 'react'

const MultiSelectListItem = ({ item, selectedData, setSelectedData, setValidate, setInputValue }) => {
  const [active, setActive] = useState(false)

  const onClickHandler = () => {
    if (!(selectedData.find(x => x === item.name))) {
      setActive(!active)
      setSelectedData(prev => [...prev, item.name])
    } else {
      setActive(false)
      setSelectedData(prev => prev.filter(x => x !== item.name))
    }
    setInputValue('')
  }

  useEffect(() => {
    if (selectedData.find(x => x === item.name)) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [selectedData])

  useEffect(() => {
    if (selectedData.length > 0) {
      setValidate(false)
    }
  }, [selectedData])

  return (
    <div
      className={`multi-select__list__item ${active ? 'active' : ''}`}
      onClick={onClickHandler}
      role="button"
      tabIndex={0}
    >
      {item.name}
    </div>
  )
}

export default MultiSelectListItem