const rangeValidate = (targetValue: number, currentRangeValues: Array<number>, minRangeValue: number, maxRangeValue: number, valueStatus: string): boolean => {
  if (valueStatus === 'min') {
    if (targetValue < minRangeValue || targetValue > maxRangeValue || currentRangeValues[1] < targetValue || !targetValue) {
      return true
    }
  } else {

    if (targetValue > maxRangeValue || targetValue < minRangeValue || currentRangeValues[0] > targetValue || !targetValue) {
      return true
    }
  }

  return false
}

export default rangeValidate