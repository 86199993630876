import { Range, getTrackBackground } from 'react-range'
import {memo} from "react";

interface IRange {
  values: Array<number>
  setValues: (val: Array<number>) => void
  setPrevData: (val: Array<number>) => void
  maxValue: number
  minValue: number
}

const CustomRange = ({ values, setValues, maxValue, minValue, setPrevData }) => {
  return (
    <Range
      values={values}
      step={1}
      min={minValue}
      max={maxValue}
      onChange={values => {
        setValues(values)
        setPrevData(values)
      }}
      renderTrack={({ props, children }) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          className="range"
        >
          <div
            ref={props.ref}
            className="range__scale"
            style={{
              background: getTrackBackground({
                values,
                colors: ['#ccc', '#099C73', '#ccc'],
                min: minValue,
                max: maxValue,
              }),
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props, isDragged }) => (
        <div
          {...props}
          className="range__thumb"
        >
          <div
            className="range__thumb__icon left"
          />
          <div
            className="range__thumb__icon"
          />
        </div>
      )}
    />
  )
}

export default memo(CustomRange)