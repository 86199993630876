import emptyImg from '../image/empty-job.svg'
import {Link} from "react-router-dom";

const EmptyJob = () => {

  return (
    <div className="empty-job">
      <div className="empty-job__inner">
        <div className="empty-job__content">
          <img src={emptyImg} alt="empty" className="empty-job__img"/>
          <p className="empty-job__headline">
            Ooops!
          </p>
          <p className="empty-job__description">
            Looks like what you are looking for has been deleted.
          </p>
          <Link to="/recruitment/jobs" className="empty-job__button button--filled">
            Back to all jobs
          </Link>
        </div>
      </div>
    </div>
  )
}

export default EmptyJob