import {memo, useEffect, useRef, useState} from "react";
import {IQuestion} from "../../../types/redux-types";
import bool from '../../../image/icon/application_bool_ic.svg'
import checkbox from '../../../image/icon/application_checkbox_ic.svg'
import radio from '../../../image/icon/application_radio_ic.svg'
import text from '../../../image/icon/application_text_ic.svg'
import droppable from '../../../image/icon/droppable_select_ic.svg'
import Answer from "./Answer";
import {useTypedSelector} from "../../../hook/useTypedSelector";
import {changeApplicationForm} from "../../../store/action-creators";
import {store} from "../../../store";
import {
    arrayMove,
    rectSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable
} from "@dnd-kit/sortable";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor, TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {restrictToParentElement, restrictToWindowEdges} from "@dnd-kit/modifiers";

const Question = (
    {
        item,
        tabId,
        setNewQuestionOpen,
        newQuestionOpen,
        listeners,
        activeDragId
    }
        :
        {
            item: IQuestion,
            tabId: number,
            newQuestionOpen?: boolean,
            id?: number,
            setNewQuestionOpen?: (value: boolean) => void,
            listeners?:any,
            activeDragId?:any
        }
) => {
    const form = useTypedSelector(state => state.screen.applicationForm.find(x => x.id === tabId))

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<null | HTMLDivElement>(null)
    const [edit, setEdit] = useState(newQuestionOpen);

    const [required, setRequired] = useState(item.required);
    const [name, setName] = useState(item.name);
    const [type, setType] = useState(item.type);
    const [answers, setAnswers] = useState(item.answer);
    const [newQuestion, setNewQuestion] = useState('');

    //validation
    const [newOptionError, setNewOptionError] = useState(false);
    const [nameError, setNameError] = useState(false);

    useEffect(() => {
        const closeDropdown = (e: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setDropdownOpen(false)
            }
        }
        document.addEventListener('click', closeDropdown)
        return () => document.removeEventListener('click', closeDropdown)
    }, [dropdownRef])

    const saveQuestion = (e: any) => {
        e.preventDefault()
        if ((name !== '' && (type === 'yesno' || type === 'freetextfield')) || (name !== '' && answers.length > 0&&!(answers.find(x=>x.name==='')))) {
            if (newQuestionOpen) {
                //if new question
                store.dispatch(changeApplicationForm({
                    ...form, question: [...form.question, {
                        id: item.id,
                        name: name,
                        type: type,
                        answer: type === 'onefromlist' || type === 'multiplechoice' || type === 'dropableselect' ? answers : [],
                        required: required,
                        userAnswer:null,
                    }]
                }))
                setNewQuestionOpen(false)
            } else {
                //if edit question
                store.dispatch(changeApplicationForm({
                    ...form, question: form.question.map(x => x.id === item.id ?
                        {
                            id: item.id,
                            name: name,
                            type: type,
                            answer: type === 'onefromlist' || type === 'multiplechoice' || type === 'dropableselect' ? answers : [],
                            required: required,
                            userAnswer:null,
                        }
                        : x)
                }))
                setAnswers(prev => type === 'onefromlist' || type === 'multiplechoice' || type === 'dropableselect' ? prev : [])
                setNewQuestion('')
                setEdit(false)
            }
        } else {
            name === '' && setNameError(true)
            answers.length === 0 && setNewOptionError(true)
        }
    }

    const deleteQuestion = (e) => {
        e.preventDefault()
        if (newQuestionOpen) {
            setNewQuestionOpen(false)
        } else {
            store.dispatch(changeApplicationForm({
                ...form, question: form.question.filter(x => x.id !== item.id)
            }))
        }
    }

    const onSortEnd = ({active, over}: DragEndEvent) => {
        setAnswers(prev => arrayMove(prev, prev.indexOf(prev.find(x=>x.id===active.id)),prev.indexOf(prev.find(x=>x.id===over.id))))
    }

    const sensors = useSensors(
        useSensor(TouchSensor),
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const saveAnswer=()=>{
        if (newQuestion !== '') {
            const highestId = getHighestId(answers)
            setAnswers(prev => [...prev, {
                id: highestId + 1,
                name: newQuestion
            }])
            setNewQuestion('')
            setNewOptionError(false)
        } else {
            setNewOptionError(true)
        }
    }

    return <div className={edit||activeDragId===item.id ? 'application_form__question active' : 'application_form__question'} >
        <div className="application_form__question__left">
            {
                !newQuestionOpen && <svg width="16" height="16" viewBox="0 0 16 16" fill="none"  {...listeners}
                                         xmlns="http://www.w3.org/2000/svg"
                                         style={{width: '16px', height: '16px'}}>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M1.59961 8.00065C1.59961 7.63246 1.89809 7.33398 2.26628 7.33398H13.4663C13.8345 7.33398 14.1329 7.63246 14.1329 8.00065C14.1329 8.36884 13.8345 8.66732 13.4663 8.66732H2.26628C1.89809 8.66732 1.59961 8.36884 1.59961 8.00065Z"
                          fill="#C1C5CB"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M1.59961 4.00065C1.59961 3.63246 1.89809 3.33398 2.26628 3.33398H13.4663C13.8345 3.33398 14.1329 3.63246 14.1329 4.00065C14.1329 4.36884 13.8345 4.66732 13.4663 4.66732H2.26628C1.89809 4.66732 1.59961 4.36884 1.59961 4.00065Z"
                          fill="#C1C5CB"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M1.59961 12.0007C1.59961 11.6325 1.89809 11.334 2.26628 11.334H13.4663C13.8345 11.334 14.1329 11.6325 14.1329 12.0007C14.1329 12.3688 13.8345 12.6673 13.4663 12.6673H2.26628C1.89809 12.6673 1.59961 12.3688 1.59961 12.0007Z"
                          fill="#C1C5CB"/>
                </svg>
            }
        </div>
        <div className="application_form__question__right">
            <div className='application_form__question__top'>
                {edit ?
                    <>
                        <label htmlFor={`name${tabId}${item.id}`}
                               className='application_form__question__label'>Question</label>
                        <input type='text'
                               className={`application_form__question__name_input ${nameError ? 'error' : ''}`}
                               id={`name${tabId}${item.id}`}
                               value={name}
                               onChange={(e) => {
                                   setName(e.target.value)
                                   nameError && setNameError(false)
                               }}
                               placeholder={'Type here'}
                        />
                        <div className='application_form__dropdown' ref={dropdownRef}>
                            <button
                                className={`application_form__question__name_input application_form__dropdown__text ${dropdownOpen ? 'active' : ''}`}
                                onClick={() => setDropdownOpen(prev => !prev)}
                            >
                                <img src={compareType(type).image} alt='dropdown'
                                     className='application_form__dropdown__image'/>
                                {
                                    compareType(type).name
                                }
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.99967 4.375H2.98711C2.20757 4.375 1.81717 5.3175 2.36839 5.86872L6.38095 9.88128C6.72266 10.223 7.27668 10.223 7.61839 9.88128L11.631 5.86872C12.1822 5.3175 11.7918 4.375 11.0122 4.375H6.99967Z"
                                        fill="#999EA5"/>
                                </svg>
                            </button>
                            {
                                dropdownOpen && <div className='application_form__dropdown__list' id={'dropdownId'}>
                                    <button className='application_form__dropdown__item'
                                            onClick={() => {
                                                setType('onefromlist')
                                                setDropdownOpen(false)
                                            }}
                                    >
                                        <img src={compareType('onefromlist').image}
                                             className='application_form__dropdown__image' alt={'dropdown'}/>
                                        {
                                            compareType('onefromlist').name
                                        }
                                    </button>
                                    <button className='application_form__dropdown__item'
                                            onClick={() => {
                                                setType('multiplechoice')
                                                setDropdownOpen(false)
                                            }}
                                    >
                                        <img src={compareType('multiplechoice').image}
                                             className='application_form__dropdown__image' alt={'dropdown'}/>
                                        {
                                            compareType('multiplechoice').name
                                        }
                                    </button>
                                    <button className='application_form__dropdown__item'
                                            onClick={() => {
                                                setType('freetextfield')
                                                setDropdownOpen(false)
                                            }}
                                    >
                                        <img src={compareType('freetextfield').image}
                                             className='application_form__dropdown__image' alt={'dropdown'}/>
                                        {
                                            compareType('freetextfield').name
                                        }
                                    </button>
                                    <button className='application_form__dropdown__item'
                                            onClick={() => {
                                                setType('dropableselect')
                                                setDropdownOpen(false)
                                            }}
                                    >
                                        <img src={compareType('dropableselect').image}
                                             className='application_form__dropdown__image' alt={'dropdown'}/>
                                        {
                                            compareType('dropableselect').name
                                        }
                                    </button>
                                    <button className='application_form__dropdown__item'
                                            onClick={() => {
                                                setType('yesno')
                                                setDropdownOpen(false)
                                            }}
                                    >
                                        <img src={compareType('yesno').image}
                                             className='application_form__dropdown__image'
                                             alt={'dropdown'}/>
                                        {
                                            compareType('yesno').name
                                        }
                                    </button>

                                </div>
                            }
                        </div>
                    </>
                    :
                    <>
                        <p className='application_form__question__title'>{name}</p>
                        <p className='application_form__question__dropdown'>
                            {
                                compareType(type).name
                            }
                        </p>
                    </>
                }
            </div>
            <div className='application_form__question__answer'>
                {
                    edit ?
                        <div className='application_form__question__answer_edit_mode'>
                            {
                                type === 'yesno' && <>
                                    <p className='application_form__question__button_edit_mode boolean'>Yes</p>
                                    <p className='application_form__question__button_edit_mode boolean'>No</p>
                                </>
                            }
                            {
                                type === 'freetextfield' &&
                                <p className='application_form__question__button_edit_mode'>Type here</p>
                            }
                            {
                                (type === 'onefromlist' || type === 'multiplechoice' || type === 'dropableselect') &&
                                <div className='application_form__answer'>

                                    <DndContext
                                        collisionDetection={closestCenter}
                                        sensors={sensors}
                                        onDragEnd={onSortEnd}
                                        modifiers={[restrictToWindowEdges,restrictToParentElement]}
                                    >
                                        <SortableContext strategy={rectSortingStrategy} items={answers}>
                                            <div>{
                                                answers.map((items) => {
                                                    return <Answer item={items} key={items.id} setAnswers={setAnswers} id={items.id} type={type}/>
                                                })
                                            }
                                            </div>
                                        </SortableContext>
                                    </DndContext>
                                    <div className={`application_form__answer__item new_question`} key={item.id} >
                                        {
                                            type==='onefromlist'&&<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="8" cy="8" r="7.5" fill="white" stroke="#C1C5CB"/>
                                            </svg>
                                        }
                                        {
                                            type==='dropableselect'&&<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" stroke="#C1C5CB"/>
                                                <path d="M8.00093 6.5H5.70803C5.26258 6.5 5.0395 7.03857 5.35448 7.35355L7.64737 9.64645C7.84264 9.84171 8.15922 9.84171 8.35448 9.64645L10.6474 7.35355C10.9624 7.03857 10.7393 6.5 10.2938 6.5H8.00093Z" fill="#C1C5CB"/>
                                            </svg>
                                        }
                                        {
                                            type==='multiplechoice'&&<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" stroke="#C1C5CB"/>
                                            </svg>
                                        }
                                        <input
                                            className={`application_form__answer__input ${newOptionError ? 'error' : ''}`}
                                            placeholder={'Add option'}
                                            value={newQuestion}
                                            onChange={(e) => {
                                                setNewQuestion(e.target.value)
                                                newOptionError && setNewOptionError(false)
                                            }}
                                            onKeyDown={(e)=>{
                                                if(e.key==='Enter'){
                                                    saveAnswer()
                                                }
                                            }}
                                        />
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             className='application_form__answer__change'
                                             onClick={() => {
                                               saveAnswer()
                                             }}
                                             xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M2.5 11.625L5.96154 15L17.5 3.75" stroke="#099C73"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <p className='application_form__question__text'>
                            {
                                type === 'yesno' && 'Yes/No'
                            }
                            {
                                (type === 'onefromlist' || type === 'multiplechoice' || type === 'dropableselect') && answers.map((item, index) => {
                                    return index + 1 !== answers.length ? item.name + ' / ' : item.name
                                })
                            }
                            {
                                type === 'freetextfield' && 'Type here'
                            }
                        </p>
                }
            </div>
            <div className='application_form__question__bottom'>
                {
                    edit ? <>
                            <div className="custom-checkbox">
                                <input type="checkbox" className="custom-checkbox__input" id={`required${item.id}${tabId}`}
                                       checked={required}
                                       onChange={(e) => {
                                           setRequired(prev => !prev)
                                       }}
                                />
                                <label htmlFor={`required${item.id}${tabId}`} className="custom-checkbox__label">
                                    <div className="custom-checkbox__item">
                                        <span/>
                                    </div>
                                    <p className="custom-checkbox__text application_form__required"
                                       style={{fontSize: '12px'}}>Required question</p>
                                </label>
                            </div>
                            <div className='application_form__question__buttons'>
                                {
                                    (form.question.length>1||newQuestionOpen)&&<button className='application_form__question__edit_button margin'
                                            onClick={e => deleteQuestion(e)}
                                    >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.5 5H4.16667H17.5" stroke="#FE4672" strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path
                                                d="M6.66699 4.99935V3.33268C6.66699 2.89065 6.84259 2.46673 7.15515 2.15417C7.46771 1.84161 7.89163 1.66602 8.33366 1.66602H11.667C12.109 1.66602 12.5329 1.84161 12.8455 2.15417C13.1581 2.46673 13.3337 2.89065 13.3337 3.33268V4.99935M15.8337 4.99935V16.666C15.8337 17.108 15.6581 17.532 15.3455 17.8445C15.0329 18.1571 14.609 18.3327 14.167 18.3327H5.83366C5.39163 18.3327 4.96771 18.1571 4.65515 17.8445C4.34259 17.532 4.16699 17.108 4.16699 16.666V4.99935H15.8337Z"
                                                stroke="#FE4672" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path d="M8.33301 9.16602V14.166" stroke="#FE4672" strokeWidth="1.5"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M11.667 9.16602V14.166" stroke="#FE4672" strokeWidth="1.5"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        Delete
                                    </button>
                                }
                                <button className='application_form__question__edit_button'
                                        onClick={(e) => saveQuestion(e)}
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.5 11.625L5.96154 15L17.5 3.75" stroke="#099C73" strokeWidth="1.5"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    Save
                                </button>
                            </div>
                        </>
                        :
                        <button className='application_form__question__edit_button' onClick={() => {
                            setEdit(true)
                        }
                        }>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.167 2.5009C14.3859 2.28203 14.6457 2.10842 14.9317 1.98996C15.2176 1.87151 15.5241 1.81055 15.8337 1.81055C16.1432 1.81055 16.4497 1.87151 16.7357 1.98996C17.0216 2.10842 17.2815 2.28203 17.5003 2.5009C17.7192 2.71977 17.8928 2.97961 18.0113 3.26558C18.1297 3.55154 18.1907 3.85804 18.1907 4.16757C18.1907 4.4771 18.1297 4.7836 18.0113 5.06956C17.8928 5.35553 17.7192 5.61537 17.5003 5.83424L6.25033 17.0842L1.66699 18.3342L2.91699 13.7509L14.167 2.5009Z"
                                    stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Edit
                        </button>
                }
            </div>
        </div>
    </div>
}

export default memo(Question)


export const getHighestId = (array) => {
    let index = 0
    array.map(item => {
        item.id > index ? index = item.id : index
    })
    return index
}
const compareType = (type) => {
    switch (type) {
        case 'yesno':
            return {
                name: 'Yes/No',
                image: bool
            }
        case 'onefromlist':
            return {
                name: 'One from list',
                image: radio
            }
        case 'freetextfield':
            return {
                name: 'Free text field',
                image: text
            }
        case 'multiplechoice':
            return {
                name: 'Multiple choice',
                image: checkbox
            }
        case 'dropableselect':
            return {
                name: 'Droppable select',
                image: droppable
            }
    }
}