import React from "react";
import { components } from "react-select";

const MenuList = (props) => {
  const handleClick = () => {
    props.sendDataToParent(true);
  };

  return (
    <div>
      <components.MenuList {...props} className="workflow-select-option">
      <div onClick={handleClick} className="manage-team__text manage-team__text--green" style={{padding:"10px"}}>Create New</div>
        {props.children}
      </components.MenuList>
    </div>
  );
};

export default MenuList;

