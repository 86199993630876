import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import Question from "./Question";
import {memo} from "react";

const QuestionWrap = ({item, tabId, id,activeDragId}) => {
    const {
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: item.id});
    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return <div ref={setNodeRef} style={style}>
        <Question item={item} tabId={tabId} id={id} listeners={listeners} activeDragId={activeDragId}/>
    </div>
}

export default memo(QuestionWrap)