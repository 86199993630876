import {memo, useState} from "react";
import Question, {getHighestId} from "./Question";
import {store} from "../../../store";
import {changeApplicationForm} from "../../../store/action-creators";
import {IApplicationForm} from "../../../types/redux-types";
import plusIc from '../../../image/icon/plus_ic.svg'
import {
    DndContext,
    DragEndEvent,
    closestCenter,
    PointerSensor,
    KeyboardSensor,
    useSensor,
    useSensors, TouchSensor, UniqueIdentifier,
} from '@dnd-kit/core';
import {
    SortableContext,
    arrayMove,
    rectSortingStrategy,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {
    restrictToParentElement,
    restrictToVerticalAxis,
    restrictToWindowEdges
} from "@dnd-kit/modifiers";
import QuestionWrap from "./QuestionWrap";

const ApplicationItem = ({item, index}: { item: IApplicationForm, index: number }) => {
    const [activeSwitcher, setActiveSwitcher] = useState(true);
    const [questionOpen, setQuestionOpen] = useState(false);
    const [newQuestionOpen, setNewQuestionOpen] = useState(false);
    const [activeIdDrag, setActiveIdDrag] = useState<UniqueIdentifier>(0);
    const sensors = useSensors(
        useSensor(TouchSensor),
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const onSortEnd = ({active, over}: DragEndEvent) => {
        setActiveIdDrag(0)
        store.dispatch(changeApplicationForm({...item, question: arrayMove(item.question, item.question.indexOf(item.question.find(x=>x.id===active.id)), item.question.indexOf(item.question.find(x=>x.id===over.id)))}))
    }
    const onSortOver=({active, over}: DragEndEvent) => {
        store.dispatch(changeApplicationForm({...item, question: arrayMove(item.question, item.question.indexOf(item.question.find(x=>x.id===active.id)), item.question.indexOf(item.question.find(x=>x.id===over.id)))}))
    }
    const onSortStart=({active}: DragEndEvent) => {
        setActiveIdDrag(active.id)
    }
    return <div className='application_form'>
        <div className='application_form__top'>
            <div className="switcher">
                <button className={`switcher__body ${activeSwitcher ? 'active' : ''}`}
                        onClick={(e) => {
                            e.preventDefault()
                            activeSwitcher && questionOpen && setQuestionOpen(false)
                            setActiveSwitcher(prev => !prev)
                            store.dispatch(changeApplicationForm({...item, activeTab: !activeSwitcher}))
                        }}
                />
            </div>
            <button
                className={`application_form__top__wrap ${questionOpen ? 'active' : ''} ${!activeSwitcher ? 'no-cursor' : ''}`}
                onClick={(e) => {
                    e.preventDefault()
                    activeSwitcher && setQuestionOpen(prev => !prev)
                }}
            >
                <div className={`application_form__top__name ${activeSwitcher ? 'active' : ''}`}>
                    {`${index}. ${item.name}`}
                </div>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 3L5 7L9 3" stroke="#B0BBCB" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            </button>
        </div>
        <div className='application_form__body'>
            {
                activeSwitcher && questionOpen && <DndContext
                    collisionDetection={closestCenter}
                    sensors={sensors}
                    onDragEnd={onSortEnd}
                    onDragOver={onSortOver}
                    onDragStart={onSortStart}
                    modifiers={[ restrictToWindowEdges,restrictToParentElement]}
                >
                    <SortableContext strategy={rectSortingStrategy} items={item.question}>
                        <div>{
                            item.question.map((items) => {
                                return <QuestionWrap key={items.id} item={items} tabId={item.id} id={items.id} activeDragId={activeIdDrag}/>
                            })
                        }
                        </div>

                    </SortableContext>
                </DndContext>
            }
            {
                activeSwitcher && questionOpen &&
                (
                    !newQuestionOpen ?
                        <button className="application_form__new_question"
                                onClick={(e) => setNewQuestionOpen(true)}>
                            <img src={plusIc} alt="plus icon" className="application_form__new_question--icon"/>
                            <p className="application_form__new_question--text">One more question</p>
                        </button>
                        :
                        <Question
                            item={{
                                id: getHighestId(item.question) + 1,
                                name: '',
                                type: 'yesno',
                                answer: [],
                                required: false,
                                userAnswer:null,
                            }}
                            tabId={item.id}
                            newQuestionOpen={newQuestionOpen}
                            setNewQuestionOpen={setNewQuestionOpen}
                        />
                )
            }
        </div>
    </div>
}

export default memo(ApplicationItem)




