import {combineReducers} from 'redux'
import {screenReducer} from "./screenReducer";
import {applyReducer} from "./ApplyReducer";


export const rootReducer = combineReducers({
    screen:screenReducer,
    apply:applyReducer
})

export type RootState = ReturnType<typeof rootReducer>


