import {IAnswer, IQuestion} from "../../types/redux-types";
import {memo, useState} from "react";
import {useTypedSelector} from "../../hook/useTypedSelector";
import {changeApplicationForm, setErrorArray} from "../../store/apply-action-creators";
import {store} from "../../store";
import {default as ReactSelect} from 'react-select'
import {selectCustomStyle} from "../../styles/selectCustomStyle";

const ApplyQuestion = ({item, tabName, questionIndex}: { item: IQuestion, tabName: string, questionIndex: number }) => {
	const [freeTextFieldAnswer, setFreeTextFieldAnswer] = useState<string>(item.userAnswer === null ? '' : item.userAnswer as string);
	const tab = useTypedSelector(state => state.apply.applicationForm.find(x => x.name === tabName))
	const errorArray = useTypedSelector(state => state.apply.errorArray)
	const setAnswer = (answer: string | boolean | Array<IAnswer> | IAnswer) => {
		store.dispatch(changeApplicationForm({
			...tab, question: tab.question.map(value => item.id === value.id ? {...item, userAnswer: answer} : value)
		}))
		if (errorArray.includes(item.id)) {
			store.dispatch(setErrorArray(errorArray.filter(x => x !== item.id)))
		}
	}

	const reactSelectOptions: any = item.answer.map(item => {
		return {
			label: item.name,
			value: item.id
		}
	})

	return <div className='apply__question'>
		<p className={`apply__question__title ${errorArray.includes(item.id) ? 'error' : ''}`}>
			{questionIndex}. {item.name}
			{
				item.required && <span>*</span>
			}
		</p>
		<div className='apply__question__answer'>
			{
				item.type === 'yesno' && <>
            <button className={`apply__question__answer_boolean ${item.userAnswer === true ? 'active' : ''}`}
                    onClick={() => {
											item.userAnswer===true?setAnswer(null):setAnswer(true)
                    }}
            >
                Yes
            </button>
            <button className={`apply__question__answer_boolean ${item.userAnswer === false ? 'active' : ''}`}
                    onClick={() => {
											item.userAnswer===false?setAnswer(null):setAnswer(false)
                    }}
            >
                No
            </button>
        </>
			}
			{
				item.type === 'onefromlist' && <div className='apply__checkbox'>
					{
						item.answer.map(value => {
							return <button className={'apply__checkbox__button'} key={value.id} onClick={() => {
								if(item.userAnswer!==null&&(item.userAnswer as IAnswer).id===value.id) {
									setAnswer(null)
								}else{
									setAnswer(value)
								}
							}}>
								<p className={'apply__checkbox__answer'}>
									{
										item.userAnswer !== null && value.id === (item.userAnswer as IAnswer).id ?
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="8" cy="8" r="7.5" fill="white" stroke="#099C73"/>
												<circle cx="8" cy="8" r="4" fill="#099C73"/>
											</svg>
											:
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="8" cy="8" r="7.5" fill="white" stroke="#099C73"/>
											</svg>
									}
									{value.name}
								</p>
							</button>
						})
					}
        </div>
			}
			{
				item.type === 'multiplechoice' && <div className='apply__checkbox'>
					{
						item.answer.map(value => {
							return <button className={'apply__checkbox__button'} key={value.id} onClick={() => {

								if (Array.isArray(item.userAnswer) && item.userAnswer.find(x => x.id === value.id)) {
									setAnswer(item.userAnswer.filter(x => x.id !== value.id))
								} else {
									if (item.userAnswer === null) {
										setAnswer([...[], value])
									} else {
										setAnswer([...item.userAnswer as Array<IAnswer>, value])
									}
								}

							}}>
								<p className='apply__checkbox__answer'>
									{
										Array.isArray(item.userAnswer) && item.userAnswer.find(x => x.id === value.id) ?
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" stroke="#099C73"/>
												<path d="M4 8L6.66667 10.5L12 5.5" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round"
												      strokeLinejoin="round"/>
											</svg>
											:
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" stroke="#099C73"/>
											</svg>
									}
									{value.name}
								</p>
							</button>
						})
					}
        </div>
			}
			{
				item.type === 'dropableselect' && <div className={'apply__question__select'}>
            <ReactSelect
                options={reactSelectOptions}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                isClearable
                onChange={(currentValue: any) => {
	                if(currentValue===null){
										setAnswer(null)
	                }else{
		                setAnswer({
			                name: currentValue.label,
			                id: currentValue.value
		                })
	                }
								}}
                value={
									reactSelectOptions.find(x => x.value === (item.userAnswer as IAnswer)?.id)
								}
                styles={selectCustomStyle}
                id={"select"+item.id}
                instanceId={"select"+item.id}
            />
        </div>
			}
			{
				item.type === 'freetextfield' &&
        <textarea className={'apply__question__textarea'}
                  onBlur={e => setAnswer(e.target.value)}
                  placeholder={'Type here'}
                  value={freeTextFieldAnswer}
                  onChange={(e) => {
					          if (errorArray.includes(item.id) && e.target.value !== '') {
						          store.dispatch(setErrorArray(errorArray.filter(x => x !== item.id)))
					          }
					          setFreeTextFieldAnswer(e.target.value)
				          }}
        />
			}
		</div>
	</div>
}
export default memo(ApplyQuestion)