import { useEffect, useState } from "react";
import axios from "axios";
import { store } from "../../store";
import {
	changeAbout,
	changeActionState, changeApplicationForm, changeAttachApplicationForm, changeBENEFITS, changeJobBoards,
	changeJobDetails,
	changeRequirements,
	setApplicationForm,
} from "../../store/action-creators";
import InnerScreen from "./InnerScreen";
import { useParams } from "react-router-dom";
import { ActionState } from "../../store/redux-enums";
import { getEnv } from "@urecruits/api";
import { useHasPermission } from "@ucrecruits/globalstyle/components/AuthProvider/useHasPermission";
import { modulesType, permissionType } from "@ucrecruits/globalstyle/context/types";


const setData = (WrappedComponent, setDefaultData,{module,permission=''}:{module:modulesType|modulesType[],permission:permissionType | permissionType[] | ""}) => {
	return function (props) {

	const {checkUserPermission} = useHasPermission()

		const location = useParams();
		const [loader, setLoader] = useState(true);
		const [checkEmpty, setCheckEmpty] = useState(false);

		useEffect(() => {
			module && checkUserPermission(module,permission) && setDefaultData(location, setLoader, setCheckEmpty);
		}, []);

		return <WrappedComponent {...props} loader={loader} checkEmpty={checkEmpty}/>;
	};
};

export const setApplicationFormData = () => {
	axios("https://cms-dev.urecruits.com/predefined-questions-create-jobs?_sort=id:ASC").then((res) => {
		store.dispatch(
			setApplicationForm(res.data.map((item) => {
				return {
					name: item.name,
					question: item.question.map((item, index) => {
						return {
							id: index + 1,
							name: item.name,
							userAnswer: null,
							required: item.required,
							answer: item.answer.map((item, index) => {
								return {
									id: index + 1,
									name: item.name,
								};
							}),
							type: item.type,
						};
					}),
					id: item.id,
					activeTab: true,
				};
			})),
		);
	});
};

export const CreateJob = setData(InnerScreen, (location, setLoader, setCheckEmpty) => {
	// TODO: need to add store value for screen type edit/create
	store.dispatch(changeActionState(ActionState.ACTION_STATE_SAVE));

	setApplicationFormData();
	setLoader(false);
	setCheckEmpty(false);
},{module:"job-post",permission:"add"});

export const EditJob = setData(InnerScreen, (location, setLoader, setCheckEmpty) => {
	store.dispatch(changeActionState(ActionState.ACTION_STATE_EDIT));
	const {API_RECRUITMENT} = getEnv();

	// TODO: need to add store value for screen type edit/create
	const token: string = localStorage.getItem("token");
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	axios.get(`${API_RECRUITMENT}/api/job/company/${location.value}`, config)
		.then(res => {
			if (res.data) {
				store.dispatch(changeJobDetails({
					jobTitle: res.data.title,
					employer: res.data.employer,
					consultancy: res.data.consultancy,
					jobLocation: res.data.locations?.map(item => {
						return {
							value: `${item.city}, ${item.state}`,
							label: `${item.city}, ${item.state}`,
							id: item.id,
						};
					}),
					remoteLocation: res.data.remoteLocation,
					salaryRangeMonth: [res.data.salaryMonthMin, res.data.salaryMonthMax],
					salaryRangeYear: [res.data.salaryYearMin, res.data.salaryYearMax],
					negotiableSalary: res.data.negotiable,
					jobDescription: res.data.description,
					shortJobDescription: res.data.shortDescription,
					opening: {value: res.data.numberOpenings, label: res.data.numberOpenings},
					jobType: {value: res.data.jobType, label: res.data.jobType},
					preferableShift: {value: res.data.preferableShift, label: res.data.preferableShift},
					position: res.data.position,
					industryType: res.data.industry,
					functionalArea: {value: res.data.functionalArea, label: res.data.functionalArea},
					noticePeriod: {value: res.data.noticePeriod, label: res.data.noticePeriod},
				}));
				store.dispatch(changeRequirements({
					skills: res.data.skills,
					experienceYears: [res.data.experienceMin, res.data.experienceMax],
					education: {value: res.data.education, label: res.data.education},
					screeningQuestions: res.data.screeningQuestions,
				}));
				store.dispatch(changeBENEFITS({
					benefitsList: res.data.benefits,
				}));
				store.dispatch(changeAbout({
					aboutCompany: res.data.aboutCompany,
					workflow: res.data.workflow ? {value: res.data.workflow.id, label: res.data.workflow.title} : {value: "", label: ""},
					careerPortal: res.data.careerPortal,
					facebook: res.data.facebook,
					instagram: res.data.instagram,
					twitter: res.data.twitter,
					linkedin: res.data.linkedin,
					careerPage: res.data.careerPortal,
					publicSearch: res.data.publicSearch,
				}));
				store.dispatch(changeJobBoards({
					integrations: res.data.jobBoards,
				}));
				store.dispatch(changeApplicationForm(res.data?.applicationForm));
				store.dispatch(changeAttachApplicationForm(res.data?.applicationForm.length > 0));
			} else {
				setCheckEmpty(true);
			}
			setLoader(false);
		});
},{module:"job-post",permission:"edit"});
