import {Link} from "react-router-dom";
import {store} from "../../../store";
import {changeSubscribePopup} from "../../../store/apply-action-creators";


const SubscribePopup = () => {

	return (
		<div className="popup apply-popup">
			<div className="popup__step">
				<div className="popup__head">
					<p className="popup__head__headline">
						Subscribe to the position
					</p>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="popup__head__close"
						onClick={() => store.dispatch(changeSubscribePopup(false))}
					>
						<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
						      strokeLinecap="round"
						      strokeLinejoin="round"/>
					</svg>
				</div>
				<div className="popup__body">
					<p className="popup__body__text">
						If you subscribe to this position, you will be notified when there is any changes regarding this job or any hiring process. You can also customize notifications in the settings, just click on the link below.
					</p>
					<Link to={"/"} className="popup__body__link">
            Manage notifications
					</Link>
				</div>
				<div className="popup__bottom end">
					<button
						className="popup__bottom__cancel button--empty"
						onClick={() => store.dispatch(changeSubscribePopup(false))}
					>
						Cancel
					</button>
					<button
						className="popup__bottom__button button--filled"
						onClick={() => store.dispatch(changeSubscribePopup(false))}
					>
            Subscribe
					</button>
				</div>
			</div>
		</div>
	);
};
export default SubscribePopup;