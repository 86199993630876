import ApplicationItem from "../ApplicationFormComponents/ApplicationItem";
import {useTypedSelector} from "../../../hook/useTypedSelector";
import {store} from "../../../store";
import {changeCurrenScreen, changeSaveTemplatePopup} from "../../../store/action-creators";
import {ScreenEnums} from "../../../store/redux-enums";

const ApplicationForm = () => {
    const formItems = useTypedSelector(state => state.screen.applicationForm)
    return <div className="step application">
        <div className="step__head">
            <p className="step__head__headline application">
                06. Application Form
            </p>
            <div className='template_dropdown'>
                <p className='template_dropdown__title'>Use a ready to use preset or customize it as you need</p>
                <label className="template_dropdown__label">Form template <span>*</span></label>
                <div
                  className={`application_form__question__name_input application_form__dropdown__text inactive`}
                >
                    Standard form
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.99967 4.375H2.98711C2.20757 4.375 1.81717 5.3175 2.36839 5.86872L6.38095 9.88128C6.72266 10.223 7.27668 10.223 7.61839 9.88128L11.631 5.86872C12.1822 5.3175 11.7918 4.375 11.0122 4.375H6.99967Z"
                          fill="#999EA5"/>
                    </svg>
                </div>
            </div>
        </div>
        <div className="step__body">
            {
                formItems.map((item, index) => {
                    return <ApplicationItem item={item} key={index} index={index + 1}/>
                })
            }
        </div>
        <div className="step__bottom application">
            <button
                className="step__bottom__prev"
                onClick={() => store.dispatch(changeCurrenScreen(ScreenEnums.JOB_BOARDS))}
            >
                Back
            </button>
            {
                /*<button
                    className="application_form__save"
                    onClick={() => store.dispatch(changeSaveTemplatePopup(true))}
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.4828 18.3327H3.51982C3.02868 18.3327 2.55765 18.1376 2.21036 17.7903C1.86307 17.443 1.66797 16.972 1.66797 16.4808V3.51787C1.66797 3.02673 1.86307 2.5557 2.21036 2.20841C2.55765 1.86112 3.02868 1.66602 3.51982 1.66602H13.705L18.3346 6.29565V16.4808C18.3346 16.972 18.1395 17.443 17.7922 17.7903C17.445 18.1376 16.9739 18.3327 16.4828 18.3327Z"
                            stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14.1654 18.334V10.834H5.83203V18.334" stroke="#099C73" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.83203 1.66602V6.66602H12.4987" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                    Save template
                </button>*/
            }
        </div>
    </div>
}
export default ApplicationForm