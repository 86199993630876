import { memo, useEffect, useState } from "react";
import { store } from "../../../store";
import { changeAbout, changeCurrenScreen } from "../../../store/action-creators";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import { default as ReactSelect } from "react-select";
import { selectCustomStyle } from "../../../styles/selectCustomStyle";
import { ScreenEnums } from "../../../store/redux-enums";
import RichTextEditor from "../../RichTextEditor";
import MenuList from "../../SingleSelectOptionWorkflows";
import { fetchData } from "../../../hook/fetchData";
import { getEnv } from "@urecruits/api";
import { Provider } from "react-redux";
import { store as store1 } from "../../../../../recruitments/store/index";
import PWPopup from "../../../../../recruitments/components/PositionWorkflow/Popup/PWPopup";
import { setTitle } from "../../../../../recruitments/store/reducers/positionWorkflowPopup";


const infoIcon = require("../../../image/icon/info_ic.svg");
const twitterIcon = require("../../../image/icon/twitter_ic.svg");
const linkedinIcon = require("../../../image/icon/linkedIn_ic.svg");
const facebookIcon = require("../../../image/icon/facebook_ic.svg");
const instagramIcon = require("../../../image/icon/instagram_ic.svg");


const AboutCompany = () => {
	const actionState = useTypedSelector(state => state.screen.actionState);
	const popupStatus = useTypedSelector(state => state.screen.draftPopup);
	const publish = useTypedSelector(state => state.screen.publishPopup);
	const initData = useTypedSelector(state => state.screen.aboutCompany);
	const [aboutCompany, setAboutCompany] = useState(initData.aboutCompany);
	const [positionWorkflow, setPositionWorkflow] = useState(initData.workflow);
	const [careerPortal, setCareerPortal] = useState(initData.careerPortal);
	const [facebook, setFacebook] = useState(initData.facebook);
	const [instagram, setInstagram] = useState(initData.instagram);
	const [twitter, setTwitter] = useState(initData.twitter);
	const [linkedin, setLinkedin] = useState(initData.linkedin);
	const [careerPage, setCareerPage] = useState(initData.careerPage);
	const [publicSearch, setPublicSearch] = useState(initData.publicSearch);
	const [workflowList,setWorkflowList]=useState([])
	const [openWorkflow,setOpenWorkflow]=useState(false)
	const jobData = useTypedSelector(state => state.screen.jobDetails);
	const [jobTitle, setJobTitle] = useState(jobData.jobTitle);
	const {API_RECRUITMENT} = getEnv();

	const defaultValues = () => {
		setAboutCompany(initData.aboutCompany);
		setPositionWorkflow(initData.workflow);
		setCareerPortal(initData.careerPortal);
		setFacebook(initData.facebook);
		setInstagram(initData.instagram);
		setTwitter(initData.twitter);
		setLinkedin(initData.linkedin);
		setCareerPage(initData.careerPage);
		setPublicSearch(initData.publicSearch);
	};

	useEffect(() => {
		defaultValues();
	}, [initData]);

	const handleDataFromChild = (data) => {
		setOpenWorkflow(data)
	  };

	useEffect(()=>{
		fetchData(`${API_RECRUITMENT}/api/workflow/company-job`)
		.then((res)=>{
			const formattedData = res.map((item) => ({
				id: item.id,
				label: item.title,
			  }));
			  setWorkflowList(formattedData);
        store1.dispatch(setTitle(jobTitle))
		})
	},[openWorkflow])

	useEffect(() => {
		if (actionState === "Edit Job") {
			store.dispatch(changeAbout({
				aboutCompany: aboutCompany,
				workflow: positionWorkflow,
				careerPortal: careerPortal,
				facebook: facebook,
				instagram: instagram,
				twitter: twitter,
				linkedin: linkedin,
				careerPage: careerPage,
				publicSearch: publicSearch,
			}));
		}
	}, [aboutCompany, positionWorkflow, careerPortal, facebook, instagram, twitter, linkedin, careerPage, publicSearch]);

	const setDataToRedux = () => {
		//TODO: need to change position workflow upd logic
		store.dispatch(changeAbout({
			aboutCompany: aboutCompany,
			workflow: positionWorkflow,
			careerPortal: careerPortal,
			facebook: facebook,
			instagram: instagram,
			twitter: twitter,
			linkedin: linkedin,
			careerPage: careerPage,
			publicSearch: publicSearch,
		}));
	};

	const moveToNextStep = () => {
		setDataToRedux();
		store.dispatch(changeCurrenScreen(ScreenEnums.JOB_BOARDS));
	};

	const moveToPrevStep = () => {
		setDataToRedux();
		store.dispatch(changeCurrenScreen(ScreenEnums.BENEFITS));
	};

	//set actually data to redux
	useEffect(() => {
		if (popupStatus || publish) {
			setDataToRedux();
		}
	}, [popupStatus,publish]);

	return (
    <>
      {openWorkflow && (
        <Provider store={store1}>
          <PWPopup
            titleTop={"Add New Workflow"}
            returnDefault={() => {}}
		      	fromJob={handleDataFromChild}
          />
        </Provider>
      )}
      <div className="step">
        <div className="step__head">
          <p className="step__head__headline">04. About company</p>
        </div>
        <div className="step__body">
          <div className="step__row">
            <div className="step__item long">
              <p className="step__item__label">About Company</p>
              <RichTextEditor
                value={aboutCompany}
                setValue={setAboutCompany}
                validateFlag={false}
                setValidate={null}
                placeholder="Write about company"
              />
            </div>
          </div>
          <div className="step__row">
            <div className={`step__item`}>
              <p className="step__item__label">Position Workflow</p>
              <ReactSelect
                options={workflowList}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(currentValue: any) => {
                  setPositionWorkflow({
                    value: currentValue.id,
                    label: currentValue.label,
                  });
                }}
                components={{
                  MenuList: (props) => (
                    <MenuList
                      {...props}
                      sendDataToParent={handleDataFromChild}
                    />
                  ),
                }}
                value={
                  workflowList.find((x) => x.label === positionWorkflow.label)
                    ? workflowList.find(
                        (x) => x.label === positionWorkflow.label
                      )
                    : ""
                }
                placeholder={"Select position workflow"}
                styles={selectCustomStyle}
                id="positionWorkflow"
                instanceId="positionWorkflow"
              />
              <p className="error-message">Required field</p>
            </div>
            <div className="step__item ">
              <p className="step__item__label">Career Portal</p>
              <input
                type="text"
                className="step__item__input"
                name="careerPortal"
                id="careerPortal"
                placeholder="Enter career portal"
                value={careerPortal}
                onChange={(e) => setCareerPortal(e.target.value)}
              />
            </div>
          </div>
          <div className="step__row">
            <div className="step__social">
              <p className="step__social__label">Twitter</p>
              <div className="step__social__inner">
                <div className="step__social__img">
                  <img src={twitterIcon} alt="instagram icon" />
                </div>
                <input
                  type="text"
                  className="step__social__input"
                  name="twitter"
                  id="twitter"
                  placeholder="Enter link to your Twitter profile"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              </div>
            </div>
            <div className="step__social">
              <p className="step__social__label">Facebook</p>
              <div className="step__social__inner">
                <div className="step__social__img">
                  <img src={facebookIcon} alt="facebook icon" />
                </div>
                <input
                  type="text"
                  className="step__social__input"
                  name="facebook"
                  id="facebook"
                  placeholder="Enter link to your Facebook profile"
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="step__row">
            <div className="step__social">
              <p className="step__social__label">Linkedin</p>
              <div className="step__social__inner">
                <div className="step__social__img">
                  <img src={linkedinIcon} alt="linkedin icon" />
                </div>
                <input
                  type="text"
                  className="step__social__input"
                  name="linkedin"
                  id="linkedin"
                  placeholder="Enter link to your Linkedin profile"
                  value={linkedin}
                  onChange={(e) => setLinkedin(e.target.value)}
                />
              </div>
            </div>
            <div className="step__social">
              <p className="step__social__label">Instagram</p>
              <div className="step__social__inner">
                <div className="step__social__img">
                  <img src={instagramIcon} alt="instagram icon" />
                </div>
                <input
                  type="text"
                  className="step__social__input"
                  name="instagram"
                  id="instagram"
                  placeholder="Enter link to your Instagram profile"
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="step__row">
            <div className="step__switcher">
              <div className="switcher">
                <button
                  className={`switcher__body ${careerPage ? "active" : ""}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setCareerPage((prev) => !prev);
                  }}
                />
              </div>
              <div className="step__switcher__content">
                <div className="step__switcher__top">
                  <p className="step__switcher__title">Career Page</p>
                  <div className="step__switcher__icon">
                    <img src={infoIcon} alt="info icon" />
                    <div className="step__switcher__info">
                      <div className="step__switcher__info--text">
                        Ability for this job to be on the career page of your
                        company’s website after settings up one of our career
                        page.
                      </div>
                      <div className="step__switcher__info--square"></div>
                    </div>
                  </div>
                </div>
                <div className="step__switcher__bottom">
                  Show this page on your career page?
                </div>
              </div>
            </div>
            <div className="step__switcher">
              <div className="switcher">
                <button
                  className={`switcher__body ${publicSearch ? "active" : ""}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setPublicSearch((prev) => !prev);
                  }}
                />
              </div>
              <div className="step__switcher__content">
                <div className="step__switcher__top">
                  <p className="step__switcher__title">Public Search</p>
                  <div className="step__switcher__icon">
                    <img src={infoIcon} alt="info icon" />
                    <div className="step__switcher__info">
                      <div className="step__switcher__info--text">
                        Ability to remove your company name from the job posting
                        which will make your company confidential and allow you
                        to post a private position.
                      </div>
                      <div className="step__switcher__info--square"></div>
                    </div>
                  </div>
                </div>
                <div className="step__switcher__bottom">
                  Show the company name in all listings?
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="step__bottom">
          <button className="step__bottom__prev" onClick={moveToPrevStep}>
            Back
          </button>
          <button
            className="step__bottom__next button--empty"
            onClick={moveToNextStep}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default memo(AboutCompany);
