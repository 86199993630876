import {IApplicationForm} from "../types/redux-types";
import {ActionApplyEnum} from "./apply-redux-enums";
import {IUserInfo} from "../types/redux-types-apply-job";
import { ActionEnums } from './redux-enums'

export const setApplicationForm = (payload: Array<IApplicationForm>) => {
	return { type: ActionApplyEnum.SET_APPLICATION_FORM, payload }
}

export const setCurrentApplicationScreen = (payload: string) => {
	return { type: ActionApplyEnum.SET_CURRENT_APPLICATION_SCREEN, payload }
}
export const changeApplicationForm=(payload:IApplicationForm)=>{
	return {type:ActionApplyEnum.CHANGE_APPLICATION_FORM, payload}
}

export const setErrorArray=(payload:Array<number>)=>{
	return {type:ActionApplyEnum.SET_ERROR_ARRAY, payload}
}

export const setCurrentScreen=(payload:string)=>{
	return {type:ActionApplyEnum.SET_CURRENT_SCREEN, payload}
}

export const setUserInfo=(payload:IUserInfo)=>{
	return {type:ActionApplyEnum.SET_USER_INFO,payload}
}

export const changeApplyPopup = (payload: boolean) => {
	return { type: ActionEnums.CHANGE_APPLY_POPUP, payload }
}

export const changeReturnToJobPopup = (payload: boolean) => {
	return {type: ActionEnums.CHANGE_RETURN_POPUP, payload};
};
export const changeSuccessfullyPopup = (payload: boolean) => {
	return {type: ActionEnums.CHANGE_SUCCESSFULLY_POPUP, payload};
};
export const changeSubscribePopup = (payload: boolean) => {
	return {type: ActionEnums.CHANGE_SUBSCRIBE_POPUP, payload};
};

export const changeSaveJobStatus = (payload: boolean) => {
	return {type: ActionEnums.CHANE_SAVE_JOB_STATUS, payload};
};

export const changeSubscribeJobStatus = (payload: boolean) => {
	return {type: ActionEnums.CHANE_SUBSCRIBE_JOB_STATUS, payload};
};

export const changeApplyJobStatus = (payload: boolean) => {
	return {type: ActionEnums.CHANE_APPLY_JOB_STATUS, payload};
};