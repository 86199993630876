import { ActionEnums } from './redux-enums'
import {
  IAboutCompany,
  IApplicationForm,
  IBenefits,
  IJobBoards,
  IJobDetails,
  IRequirements
} from '../types/redux-types'

export const changeJobDetails = (payload: IJobDetails) => {
  return { type: ActionEnums.CHANGE_JOB_DETAILS, payload }
}
export const changeRequirements = (payload: IRequirements) => {
  return { type: ActionEnums.CHANGE_REQUIREMENTS, payload }
}
export const changeBENEFITS = (payload: IBenefits) => {
  return { type: ActionEnums.CHANGE_BENEFITS, payload }
}
export const changeAbout = (payload: IAboutCompany) => {
  return { type: ActionEnums.CHANGE_ABOUT_COMPANY, payload }
}
export const changeJobBoards = (payload: IJobBoards) => {
  return { type: ActionEnums.CHANGE_JOB_BOARDS, payload }
}
export const changeCurrenScreen = (payload: string) => {
  return { type: ActionEnums.CHANGE_CURRENT_SCREEN, payload }
}
export const changeActionState = (payload: string) => {
  return { type: ActionEnums.CHANGE_ACTION_STATE, payload }
}
export const jobDetailsValidate = (payload: boolean) => {
  return { type: ActionEnums.JOB_DETAILS_VALIDATE, payload }
}
export const aboutCompanyValidate = (payload: boolean) => {
  return { type: ActionEnums.ABOUT_COMPANY_VALIDATE, payload }
}
export const requirementsValidate = (payload: boolean) => {
  return { type: ActionEnums.REQUIREMENTS_VALIDATE, payload }
}
export const changeApplicationForm = (payload: IApplicationForm) => {
  return { type: ActionEnums.CHANGE_APPLICATION_FORM, payload }
}
export const setApplicationForm = (payload: Array<IApplicationForm>) => {
  return { type: ActionEnums.SET_APPLICATION_FORM, payload }
}
export const changeDraftPopup = (payload: boolean) => {
  return { type: ActionEnums.CHANGE_DRAFT_POPUP, payload }
}
export const changeSaveTemplatePopup = (payload: boolean) => {
  return { type: ActionEnums.CHANGE_SAVE_TEMPLATE_POPUP, payload }
}
export const changeApprovalPopup = (payload: boolean) => {
  return { type: ActionEnums.CHANGE_APPROVAL_POPUP, payload }
}
export const changePublishPopup = (payload: boolean) => {
  return { type: ActionEnums.CHANGE_PUBLISH_POPUP, payload }
}
export const changeSavedPopup = (payload: boolean) => {
  return { type: ActionEnums.CHANGE_SAVED_POPUP, payload }
}
export const changeCancelPopup = (payload: boolean) => {
  return { type: ActionEnums.CHANGE_CANCEL_POPUP, payload }
}
export const changeAttachApplicationForm = (payload: boolean) => {
  return { type: ActionEnums.ATTACH_APPLICATION_FORM, payload }
}
export const changeWorkflowsList = (payload: any) => {
  return { type: ActionEnums.CHANGE_WORKFLOWS_LIST, payload }
} 
export const reset_screen_details = () => {
  return { type: "reset_screen_details" }
}
