import {memo, useState} from 'react'
import { store } from '../../../store'
import { Link } from '@reach/router'
import { changeCancelPopup, reset_screen_details } from '../../../store/action-creators'
import { useTypedSelector } from '../../../hook/useTypedSelector'
import axios from 'axios'
import { useCurrentPopupData } from '../../../hook/useCurrentPopupData'
import {getEnv} from "@urecruits/api";

const successImage = require('../../../image/icon/success_image.svg')

const CancelPopup = () => {
  const [jobId, setJobId] = useState()
  const actionState = useTypedSelector(state => state.screen.actionState)

  const initData = useTypedSelector(state => state.screen.jobDetails)
  const jobDetailsValidateValue = useTypedSelector(state => state.screen.jobDetailsValidate)
  const requirementsValidateValue = useTypedSelector(state => state.screen.requirementsValidate)
  const data = useCurrentPopupData('publish')
  const {API_RECRUITMENT} = getEnv()

  const token: string = localStorage.getItem('token')

  const [success, setSuccess] = useState(false)
  const [errorNotification, setErrorNotification] = useState('')

  const onSubmit = () => {
    if (!jobDetailsValidateValue && !requirementsValidateValue) {
      setErrorNotification('You should fill required fields')
    } else {
      actionState === "Edit Job" && (
        //TODO: need to add put logic
        console.log("send edit data")
      )
      actionState === "Create a New Job" && (
        axios.post(`${API_RECRUITMENT}/api/job`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => {
          if (res.status == 201) {
            setJobId(res.data.id)
            setSuccess(true)
          }
        })
      )
    }
  }

  return (
    <div className="popup">
      {
        !success ?
          <div className="popup__step">
            <div className="popup__head">
              <p className="popup__head__headline">
                Unsaved Changes
              </p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => store.dispatch(changeCancelPopup(false))}
                className="popup__head__close"
              >
                <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"/>
              </svg>
            </div>
            <div className="popup__body">
              <p className="popup__body__text">
                If you click off that page by accident, you lose which the information you have written.
              </p>
            </div>
            <div className="popup__bottom end">
              <Link to={'/recruitment/jobs'} className="popup__bottom__cancel button--empty" onClick={()=>store.dispatch(reset_screen_details())}>
                Discard changes
              </Link>
              <button
                className={`popup__bottom__publish button--filled ${!jobDetailsValidateValue || !requirementsValidateValue ? 'button--filled-disable' : ''}`}
                onClick={onSubmit}
              >
                Save
              </button>
              {
                errorNotification.length > 0 && (
                  <p className="error-message">{errorNotification}</p>
                )
              }
            </div>
          </div>
          :
          <div className="popup__step">
            <div className="popup__head">
              <p className="popup__head__headline">
                Published
              </p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="popup__head__close"
                onClick={() => store.dispatch(changeCancelPopup(false))}
              >
                <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"/>
              </svg>
            </div>
            <div className="popup__body">
              <img src={successImage} alt="" className="popup__body__image"/>
              <p className="popup__body__text">
                The job {initData.jobTitle} have been successfully published on <a href="https://urecruits.com/"
                                                                                   target="_blank">uRecruits</a>.
                Click <Link to={`/job/${jobId}`}>Link</Link> to see it.
              </p>
            </div>
            <div className="popup__bottom center">
              <Link
                to={'/recruitment/jobs'}
                className="popup__bottom__publish button--filled"
                onClick={() => {store.dispatch(changeCancelPopup(false)),store.dispatch(reset_screen_details())}}
              >
                Got it!
              </Link>
            </div>
          </div>
      }
    </div>
  )
}

export default memo(CancelPopup)