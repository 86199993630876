import { IAction } from '../../types/redux-types'
import { IApply } from '../../types/redux-types-apply-job'
import { ActionApplyEnum, ApplyScreenEnum } from '../apply-redux-enums'
import { ActionEnums } from '../redux-enums'

export const applyReducer = (state: IApply = initialState, action: IAction): IApply => {
  switch (action.type) {
    case ActionApplyEnum.SET_APPLICATION_FORM:
      return { ...{}, ...state, applicationForm: action.payload }
    case ActionApplyEnum.SET_CURRENT_APPLICATION_SCREEN:
      return { ...{}, ...state, applicationScreen: action.payload }
    case ActionApplyEnum.SET_CURRENT_SCREEN: {
      return { ...{}, ...state, currentScreen: action.payload }
    }
    case ActionApplyEnum.CHANGE_APPLICATION_FORM:
      return {
        ...{}, ...state,
        applicationForm: state.applicationForm.map(x => x.name === action.payload.name ? action.payload : x)
      }
    case ActionApplyEnum.SET_ERROR_ARRAY:
      return { ...{}, ...state, errorArray: action.payload }
	  case ActionApplyEnum.SET_USER_INFO:
		  return {...{}, ...state, userInfo: action.payload};
	  case ActionEnums.CHANGE_APPLY_POPUP:
		  return {...{}, ...state, applyPopup: action.payload};
	  case ActionEnums.CHANGE_RETURN_POPUP:
		  return {...{}, ...state, returnToJobPopup: action.payload};
	  case ActionEnums.CHANGE_SUCCESSFULLY_POPUP:
		  return {...{}, ...state, successfullyAppliedPopup: action.payload};
	  case ActionEnums.CHANGE_SUBSCRIBE_POPUP:
		  return {...{}, ...state, subscribePopup: action.payload};
	  case ActionEnums.CHANE_SAVE_JOB_STATUS:
		  return {...{}, ...state, saveStatus: action.payload};
	  case ActionEnums.CHANE_SUBSCRIBE_JOB_STATUS:
		  return {...{}, ...state, subscribeStatus: action.payload};
	  case ActionEnums.CHANE_APPLY_JOB_STATUS:
		  return {...{}, ...state, applyStatus: action.payload};
	  default:
		  return state;
  }
}

export const initialState: IApply = {
  applicationForm: [],
  currentScreen: ApplyScreenEnum.APPLICATION,
  applicationScreen: '',
  errorArray: [],
  userInfo: {
    firstname: '',
    lastname: '',
    middlename: '',
    industries: [],
    position: '',
    email: '',
	  experience: "",
	  avatar: "",
	  location: "",
	  phone: "",
	  ctc: "",
  },
	applyPopup: false,
	returnToJobPopup: false,
	successfullyAppliedPopup: false,
	subscribePopup: false,
	saveStatus: false,
	subscribeStatus: false,
	applyStatus: false,
}