import {memo, useState} from 'react'
import { store } from '../../../store'
import { changeSaveTemplatePopup, reset_screen_details } from '../../../store/action-creators'

const successImage = require('../../../image/icon/success_image.svg')
const alarmImage = require('../../../image/icon/alarm_image.svg')

const SaveTemplatePopup = () => {
  const [success, setSuccess] = useState(false)
  const [errorNotification, setErrorNotification] = useState('')

  const onSubmit = () => {
    setSuccess(true)
  }

  return (
    <div className="popup">
      {
        !success ?
          <div className="popup__step">
            <div className="popup__head">
              <p className="popup__head__headline">
                Change template
              </p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => store.dispatch(changeSaveTemplatePopup(false))}
                className="popup__head__close"
              >
                <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"/>
              </svg>
            </div>
            <div className="popup__body">
              <img src={alarmImage} alt="" className="popup__body__image alarm"/>
              <p className="popup__body__text">
                You have changed “Standart Form” do you want to save template?
              </p>
            </div>
            <div className="popup__bottom end">
              <button
                className="popup__bottom__cancel button--empty"
                onClick={() => store.dispatch(changeSaveTemplatePopup(false))}
              >
                Skip
              </button>
              <button
                className={`popup__bottom__publish button--filled`}
                onClick={onSubmit}
              >
                Save
              </button>
              {
                errorNotification.length > 0 && (
                  <p className="error-message">{errorNotification}</p>
                )
              }
            </div>
          </div>
          :
          <div className="popup__step">
            <div className="popup__head">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="popup__head__close"
                onClick={() => store.dispatch(changeSaveTemplatePopup(false))}
              >
                <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"/>
              </svg>
            </div>
            <div className="popup__body">
              <img src={successImage} alt="" className="popup__body__image"/>
              <p className="popup__body__headline">
                Successfully Saved
              </p>
            </div>
            <div className="popup__bottom center">
              <button
                className="popup__bottom__publish button--filled"
                onClick={() => {store.dispatch(changeSaveTemplatePopup(false)),store.dispatch(reset_screen_details())}}
              >
                Got it!
              </button>
            </div>
          </div>
      }
    </div>
  )
}

export default memo(SaveTemplatePopup)