import firebase from "firebase/app";
import 'firebase/messaging'

const firebaseConfig = {
  apiKey: "AIzaSyCixwo1k_ECNLyXNQZ6AZC2R74TOdCZJ40",
  authDomain: "fir-cloud-messaging-9464e.firebaseapp.com",
  projectId: "fir-cloud-messaging-9464e",
  storageBucket: "fir-cloud-messaging-9464e.appspot.com",
  messagingSenderId: "785743649993",
  appId: "1:785743649993:web:216e410e2297132fdf0118",
};

export const app = firebase.initializeApp(firebaseConfig);
export const messaging = firebase.messaging();
