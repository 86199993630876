import axios from "axios";
const token: string = localStorage.getItem('token')
const config = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
}

export const fetchData = async (api) => {
  const res = await axios.get(api, config)
  if (res && res.data) {
    return res.data
  }
}