import { memo, useLayoutEffect, useState } from "react";
import { ApplyScreenEnum } from "../../store/apply-redux-enums";
import { useTypedSelector } from "../../hook/useTypedSelector";
import ApplicationInner from "./ApplicationInner";
import axios from "axios";
import { store } from "../../store";
import {
  setApplicationForm,
  setCurrentApplicationScreen,
  setUserInfo,
} from "../../store/apply-action-creators";
import { useNavigate, useParams } from "react-router-dom";
import Summary from "./Summary";
import HeaderApply from "./Header";
import { getEnv } from "@urecruits/api";
import SuccessfullyPopup from "./Popups/SuccessfullyPopup";
import ReturnToJobPopup from "./Popups/ReturnToJobPopup";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const token: string = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};
const ApplyInner = () => {
  const screen = useTypedSelector((state) => state.apply);
  const errorArray = useTypedSelector((state) => state.apply.errorArray);
  const jobId = useParams().value;
  const navigate = useNavigate();
  const [jobTitle, setJobTitle] = useState(" ");
  const { API_RECRUITMENT } = getEnv();

  useLayoutEffect(() => {
    axios(`${API_RECRUITMENT}/api/job/public-job/${jobId}`, config).then((res) => {
      if (res.data) {
        const form = res.data?.applicationForm.filter((x) => x.activeTab);
        setJobTitle(res.data.title);
        store.dispatch(setApplicationForm(form));
        store.dispatch(setCurrentApplicationScreen(form[0].name));
      } else {
        //if no page found
        navigate("/");
      }
    });
    axios(`${API_RECRUITMENT}/api/candidate`, config).then((res) => {
      store.dispatch(
        setUserInfo({
          firstname: res.data.user.firstname,
          middlename: res.data.user.middlename,
          lastname: res.data.user.lastname,
          phone: res.data.user.phone,
          avatar: res.data.user.avatar,
          email: res.data.user.email,
          industries: res.data.industries.map((item) => item.label),
          position: res.data.position?.label,
          experience: res.data.experience,
          location: res.data?.location?.city
            ? `${res.data?.location?.city}, ${res.data?.location?.state}`
            : "",
          ctc: res.data.currentCtc,
        })
      );
    });
  }, []);

  return (
    <AuthGuard module='candidate'>
    <section className="job">
      <HeaderApply title={jobTitle} />
      {errorArray.length > 0 && (
        <div className={"apply_error"}>
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.00195 17C13.1441 17 16.502 13.6421 16.502 9.5C16.502 5.35786 13.1441 2 9.00195 2C4.85982 2 1.50195 5.35786 1.50195 9.5C1.50195 13.6421 4.85982 17 9.00195 17Z"
              stroke="#FE4672"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 6.5V9.5"
              stroke="#FE4672"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 12.5H9.00833"
              stroke="#FE4672"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>{errorArray.length} unfilled questions</p>
        </div>
      )}
      <div className="job__steps">
        {screen.currentScreen === ApplyScreenEnum.APPLICATION && (
          <ApplicationInner />
        )}
        {screen.currentScreen === ApplyScreenEnum.SUMMARY && <Summary />}
      </div>
      {screen.successfullyAppliedPopup && (
        <SuccessfullyPopup jobId={jobId} jobTitle={jobTitle} />
      )}
      {screen.returnToJobPopup && <ReturnToJobPopup />}
    </section>
    </AuthGuard>
  );
};
export default memo(ApplyInner);
