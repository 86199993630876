import { useTypedSelector } from "../../hook/useTypedSelector";
import ApplyQuestion from "./ApplyQuestion";
import { store } from "../../store";
import { setCurrentApplicationScreen, setCurrentScreen, setErrorArray } from "../../store/apply-action-creators";
import { ApplyScreenEnum } from "../../store/apply-redux-enums";
import { memo } from "react";
import ReturnToJobPopup from "./Popups/ReturnToJobPopup";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const ApplicationInner = () => {
  const screen = useTypedSelector(state => state.apply)
  const currentIndex = screen.applicationForm.findIndex(x => x.name === screen.applicationScreen)
  const errors = screen.errorArray

  const nextValidation = () => {
    let errorArray = []
    document.getElementsByClassName("app")[0].scrollTo(0, 0);
    screen.applicationForm.forEach(item => {
      item.name === screen.applicationScreen && item.question.forEach(value => {
        if (value.required &&
          (value.userAnswer === "" || value.userAnswer === null ||
            (Array.isArray(value.userAnswer) && value.userAnswer.length === 0))
        ) {
          errorArray = [...errorArray, value.id];
        }
      });
    });
    store.dispatch(setErrorArray(errorArray))
    if (errorArray.length === 0) {
      if (currentIndex === screen.applicationForm.length - 1) {
        store.dispatch(setCurrentScreen(ApplyScreenEnum.SUMMARY))
      } else {
        store.dispatch(setCurrentApplicationScreen(screen.applicationForm[currentIndex + 1].name))
      }
    }
  }

  const goPrev = () => {
    store.dispatch(setErrorArray([]))
    store.dispatch(setCurrentApplicationScreen(screen.applicationForm[currentIndex - 1].name))
  }

  return (
    <AuthGuard module='candidate'>
      <div className={'step'}>
        <div className={'step__head'}>
          <p className={'step__head__headline'}>{screen.applicationScreen}</p>
          <div className={'apply'}>
            {
              screen.applicationForm.map((item) => {
                return item.name === screen.applicationScreen && item.question.map((value, index) => {
                    return <ApplyQuestion item={value} key={value.id} tabName={item.name} questionIndex={index + 1}/>
                  }
                )
              })
            }
          </div>
        </div>
        <div className="apply__bottom">
          {
            currentIndex !== 0 && <button
              className="apply__bottom__prev "
              onClick={() => goPrev()}
            >
              Back
            </button>
          }
          <button
            className="apply__bottom__next button--empty"
            onClick={() => nextValidation()}
          >
            {
              currentIndex === screen.applicationForm.length - 1 ? 'Save and go to summary' : 'Next'
            }
          </button>
          {
            errors.length > 0 && (
              <p className="error-message">
                Please fill in the required fields
              </p>
            )
          }
        </div>
      </div>
			{screen.returnToJobPopup && (<ReturnToJobPopup/>)}
    </AuthGuard>
  )
}

export default memo(ApplicationInner)